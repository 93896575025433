<template>
  <div class="is-flex is-justify-content-flex-end mb-1">
    <div @click="downloadExcel()" class="button is-primary" :class="{ 'is-loading': loading }">
      <i class="fas fa-poll mr-2"></i>
      Download results
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import { utils, writeFile } from 'xlsx'

export default {
  name: 'DownloadResults',
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      allRows: []
    }
  },
  methods: {
    async downloadExcel () {
      this.loading = true
      this.allRows = []

      try {
        let currentPage = 1
        let hasNextPage = true

        const wantFullReport = confirm('Do you want to download the full report? Full report will contain answers, timestamps and number of attempts. Click Cancel for partial report.')
        const reportType = wantFullReport ? 'full' : 'partial'

        // Fetch all pages
        while (hasNextPage) {
          const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
          const result = await masterFunctionAdmin({
            methodName: 'download-results',
            sessionId: this.session.id,
            timezone: -1 * (new Date().getTimezoneOffset() / 60),
            reportType,
            page: currentPage
          })

          this.allRows = [...this.allRows, ...result.data.csvRows]

          if (result.data.nextPage) {
            currentPage = result.data.nextPage
          } else {
            hasNextPage = false
          }
        }

        // Prepare data for Excel
        const keys = Object.keys(this.allRows[0] || {})
        const aoa = this.allRows.map(obj => {
          return keys.map(key => {
            if (obj[key] instanceof Date) {
              return obj[key].toLocaleString()
            }
            return obj[key] || ''
          })
        })
        aoa.unshift(keys) // Add header row

        // Create a new workbook and add the data
        const ws = utils.aoa_to_sheet(aoa)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Results')

        // Generate and download Excel file
        const filename = `playtours_results_${this.session.id}_${new Date().toISOString()}.xlsx`
        writeFile(wb, filename)
      } catch (error) {
        console.error('Error downloading results:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
