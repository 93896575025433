<template>
  <card-component title="Add Session" icon="fa-calendar-day">
    <form v-if="form" @submit.prevent="() => {}">
      <b-field horizontal label="Name" message="Mandatory. Can be changed.">
        <b-input v-model="form.name" name="name" required />
      </b-field>
      <!-- <b-field horizontal label="Tag" message="Used to group sessions by common use, such as for a particular client or workshop.">
        <b-input v-model="form.tag" name="tag" />
      </b-field> -->
      <div class="is-size-5 has-text-weight-semibold mb-0">
        Chat
      </div>
      <div class="box">
        <article v-if="!form.allowFaciMessaging" class="message is-warning">
          <div class="message-body py-2 is-size-7">
            To allow players to message facilitators during a game, activate the option below.
            Facilitators have to access the dashboard via the Facilitator's Link to see and reply to messages.
          </div>
        </article>
        <b-field label="Allow team-to-faci messaging" message="Allow teams to send messages to faci. Faci can see and reply messages via the Facilitator's Link." horizontal>
          <b-switch v-model="form.allowFaciMessaging">
          </b-switch>
        </b-field>
        <b-field label="Allow teams to message other teams in the session" message="Allow teams to send session-wide messages that can be seen by all other teams and facis." horizontal>
          <b-switch v-model="form.allowSessionChat">
          </b-switch>
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Location
      </div>
      <div class="box">
        <b-field label="Require teams' position" message="Allow facilitators to track players' location as they play." horizontal>
          <b-switch v-model="form.requireTeamPosition">
          </b-switch>
        </b-field>
        <b-field v-if="form.requireTeamPosition" label="Allow teams to view other teams' locations" horizontal>
          <b-switch v-model="form.allowTeamPositionsView">
          </b-switch>
        </b-field>
        <b-field label="Allow location picker if GPS access is blocked" message="Allow players to manually pick their location if GPS access is blocked on their device. Strongly recommended for public games that are non-competitive." horizontal>
          <b-switch v-model="form.allowLenientLocationPicker">
          </b-switch>
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Leaderboard
      </div>
      <div class="box">
        <b-field label="Hide leaderboard" horizontal>
          <b-switch v-model="form.hideLeaderboard">
          </b-switch>
        </b-field>
        <b-field v-if="!form.hideLeaderboard" label="Use friendly leaderboard" message="A 'friendly' leaderboard hides the points of other teams in front of the viewing team, and hides the positions of the bottom 3 or 30% of the total number of team, whichever is higher." horizontal>
          <b-switch v-model="form.hasFriendlyLeaderboard">
          </b-switch>
        </b-field>
        <b-field v-if="!form.hideLeaderboard" label="Show only upon completion" message="Leaderboard will only be revealed after the team has completed the game." horizontal>
          <b-switch v-model="form.showLeaderboardAtCompletion">
          </b-switch>
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Player & Team Limits
      </div>
      <div class="box">
        <b-field label="Players can play in teams" message="If disabled, players will play individually and cannot form teams." horizontal>
          <b-switch v-model="allowTeams">
          </b-switch>
        </b-field>
        <b-field v-if="allowTeams" horizontal label="Max no. of devices in a team" message="Limit the total number of devices allowed in a team. If a new device joins a full team, the device that has been on the game the longest will be removed.">
          <b-input v-model="form.maxPlayersPerTeam" name="maxTeams" type="number" />
        </b-field>
        <b-field horizontal label="Max no. of teams" message="Limit the total number of teams allowed.">
          <b-input v-model="form.maxTeams" name="maxTeams" type="number" />
        </b-field>
        <b-field horizontal label="Max no. of devices" message="Limit the total number of devices allowed.">
          <b-input v-model="form.maxPlayers" name="maxPlayers" type="number" />
        </b-field>
      </div>

      <div class="is-size-5 has-text-weight-semibold mb-0">
        Miscellaneous
      </div>
      <div class="box">
        <b-field label="Has completion certificates" message="Completion certificates allow you to validate a team's completion to award prizes, etc. Cannot be disabled once enabled for a specific session." horizontal>
          <b-switch v-model="form.hasCompletionCert">
          </b-switch>
        </b-field>
        <b-field v-if="form.hasCompletionCert" horizontal label="Additional certificate instructions" message="Shown on the debrief page after players complete the game.">
          <b-input v-model="form.completionCertBriefing" name="maxPlayers" type="textarea"/>
        </b-field>
        <b-field horizontal label="Emails to notify for new judgements" message="Comma-separated, e.g. tom@abc.com, susan@abc.com">
          <b-input v-model="form.notifyEmails" name="notifyEmails" />
        </b-field>
        <b-field label="Auto-approve tasks requiring judgement" message="Auto-approve tasks that has the 'judged-image', 'judged-video' & 'judged-text' type." horizontal>
          <b-switch v-model="form.autoApproveJudgedTasks">
          </b-switch>
        </b-field>
        <b-field label="Enable access codes" message="If enabled, players have to enter an Access Code to start playing. Cannot be disabled once enabled." horizontal>
          <b-switch v-model="form.requireAccessCodes">
          </b-switch>
        </b-field>
        <b-field label="Allow removal of time limits (for mobility-impeded players)" message="Allow teams to remove time limits, for use if they have mobility-impeded players." horizontal>
          <b-switch v-model="form.allowRemoveTimeLimits">
          </b-switch>
        </b-field>
        <!-- <b-field label="Send team link email to players" message="Players can optionally provide their email when creating teams to receive a return link if access is lost." horizontal>
          <b-switch v-model="form.sendTeamLinkEmail">
          </b-switch>
        </b-field> -->
        <b-field
          label="Send team link email to players"
          message="Players can provide their email when creating teams to receive a return link if access is lost."
          horizontal>
            <b-select
              v-model="form.sendTeamLinkEmail"
              placeholder="Select an option"
              required
            >
              <option
                v-for="(x, index) in ['Yes, optional', 'Yes, mandatory', 'No']"
                :key="index"
                :value="x"
              >
                {{ x }}
              </option>
            </b-select>
          </b-field>
      </div>

      <hr />
      <div class="is-flex is-flex-direction-row-reverse">
        <div v-if="errorMessage" class="container">
          <div class="is-size-6 has-text-danger">
            {{ errorMessage }}
          </div>
        </div>
        <button
          @click="addAdmin"
          class="button is-primary"
          :class="{ 'is-loading': loading }"
          :disabled="!form.name || form.name.trim().length === 0"
        >
          Add session
        </button>
      </div>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'AddSessionForm',
  components: {
    CardComponent
  },
  data () {
    return {
      adventureId: this.$route.params.adventureId,
      errorMessage: null,
      loading: false,
      allowTeams: true,
      form: {
        name: null,
        tag: null,
        hideLeaderboard: false,
        hasFriendlyLeaderboard: false,
        showLeaderboardAtCompletion: false,
        allowSessionChat: false,
        allowFaciMessaging: false,
        requireTeamPosition: false,
        allowTeamPositionsView: false,
        allowLenientLocationPicker: false,
        maxTeams: null,
        maxPlayersPerTeam: null,
        maxPlayers: null,
        hasCompletionCert: false,
        completionCertBriefing: null,
        notifyEmails: null,
        autoApproveJudgedTasks: false,
        requireAccessCodes: false,
        allowRemoveTimeLimits: false,
        sendTeamLinkEmail: 'Yes, optional'
      }
    }
  },
  watch: {
    'form.requireTeamPosition': function (newVal, oldVal) {
      if (oldVal && !newVal) {
        this.form.allowTeamPositionsView = false
      }
    },
    'form.allowTeamPositionsView': function (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.form.requireTeamPosition = true
      }
    },
    'form.hideLeaderboard': function (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.form.hasFriendlyLeaderboard = false
      }
    },
    allowTeams (newVal) {
      if (!newVal) {
        this.form.maxPlayersPerTeam = 1
      } else {
        this.form.maxPlayersPerTeam = null
      }
    }
  },
  methods: {
    addAdmin () {
      if (!this.form.name || this.form.name.trim().length === 0) {
        return
      }
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'create-session',
        adventureId: this.adventureId,
        ...this.form,
        maxTeams: Number(this.form.maxTeams),
        maxPlayers: Number(this.form.maxPlayers)
      }).then(result => {
        this.form = {
          name: null,
          tag: null
        }
        this.loading = false
        this.$buefy.toast.open({
          message: 'Successfully created session!',
          type: 'is-primary',
          queue: false
        })
        this.$router.push({ name: 'managePlayTour', params: { adventureId: this.adventureId } })
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  }
}
</script>
