<template>
  <card-component v-if="!adventureId || adventureId && adventure">
    <!-- :key="`PlayToursAI ${unsavedChanges}`" -->
    <PlayToursAI
    :rawAdventureJson="cleanedRawAdventureJson"
    v-on:updateRawAdventureJson="initializeRawAdventureJson($event, true)"
    />
    <hr>

    <ActiveTeamsWarningMessage :adventureId="adventureId"></ActiveTeamsWarningMessage>

    <article v-if="shownStage" class="message mb-3" style="background-color: white;">
      <div class="is-flex is-justify-content-space-between">
        <div class="is-flex">
          <button @click="triggerBackButton()" class="button is-primary has-text-weight-bold mb-2 mr-3">
            <i class="fas fa-chevron-left fa-xs mr-2"></i>
            Back
          </button>
          <div v-if="shownStageIndex > 0" @click="toggleShowStage(shownStageIndex - 1)" class="button is-primary is-light mr-3">
            <i class="fas fa-backward mr-2"></i>
            Prev Chapter
          </div>
          <div v-if="shownStageIndex < stages.length - 1" @click="toggleShowStage(shownStageIndex + 1)" class="button is-primary is-light">
            Next Chapter
            <i class="fas fa-forward ml-2"></i>
          </div>
        </div>
        <div class="is-flex is-align-items-center">
          <div
            v-if="adventureId"
            @click="openTestPage(shownStageIndex + 1)"
            class="button is-primary is-light mr-3"
            v-bind:class="{'is-loading': loading}"
          >
            <i class="fas fa-external-link-alt mr-2"></i>
            {{`Test Chapter ${shownStageIndex + 1}`}}
          </div>
          <div v-if="!adventureId && !error" class="has-text-grey mr-3">
            You can test the game & continue editing after clicking "Create game"
          </div>
          <div class="my-0 py-0">
            <button
              @click="createAdventure(null)"
              class="button is-primary"
              :disabled="adventureId && savedPlayTours"
              v-bind:class="{'is-loading': loading}">
              <i class="fas fa-save mr-2"></i>
              {{ adventureId ? 'Save changes' : 'Create game & start testing' }}
            </button>
          </div>
        </div>
        <!-- <FaqAiChat /> -->
      </div>
      <div class="stage-header">
        <nav class="level is-mobile mb-0">
          <div class="level-left">
            <div class="level-item">
              <div @click="toggleShowStage(shownStageIndex)" class="unselectable is-clickable has-text-weight-bold is-size-4 mb-1" :class="{'has-text-danger': errorSections.includes(shownStageIndex)}">
                <div class="is-flex is-align-items-center" :class="{'is-size-7 has-text-grey': shownStage.title, 'is-size-5': !shownStage.title}">
                  Chapter {{ shownStageIndex + 1 }}
                </div>
                <div class="is-flex is-align-items-center" v-if="shownStage.title">
                  <span>{{ shownStage.title }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="level-right">
            <div class="level-item">
              <div
                v-if="adventureId"
                @click="openTestPage(shownStageIndex + 1)"
                class="button is-primary is-small is-rounded"
                v-bind:class="{'is-loading': loading}"
              >
                <i class="fas fa-external-link-alt mr-2"></i>
                {{`Save & test Chapter ${shownStageIndex + 1} in new window`}}
              </div>
            </div>
          </div> -->
        </nav>
      </div>
      <div v-show="shownStage.show" class="message-body">
        <FormInput :title="'Title'" :type="'text'" :value="shownStage.title" :helptext="`If provided, title will appear at the top instead of 'Chapter ${shownStageIndex + 1}'`" v-on:update="shownStage.title = $event" :errorMessage="getErrorMessage('title', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        <FormInput v-if="shownStage.showBriefing || shownStage.briefing" :title="'Chapter text'" :helperImage="screenshots[3]" :type="'markdown'" :value="shownStage.briefing" v-on:update="shownStage.briefing = $event" :errorMessage="getErrorMessage('briefing', { fieldName: 'stages', stageIndex: shownStageIndex })"/>

        <label class="label is-size-5 mt-3 mb-1">Tasks</label>

        <div class="is-flex is-align-items-center mb-2">
          <div class="">
            <b-switch v-model="shownStage.completeChallengesInOrder">
            </b-switch>
          </div>
          <div class="has-text-weight-semibold mr-2 mb-0">
            Must complete in order
          </div>
          <div class="">
            <b-switch v-model="shownStage.shuffleChallenges">
            </b-switch>
          </div>
          <div class="has-text-weight-semibold mr-3 mb-0">
            Shuffle tasks & prevent crowding
          </div>
          <div v-if="shownStage.shuffleChallenges" class="">
            <b-switch v-model="shownStage.shuffleChallengesPreserveSequence">
            </b-switch>
          </div>
          <div v-if="shownStage.shuffleChallenges" class="has-text-weight-semibold mr-3 mb-0">
            Preserve sequence
          </div>
        </div>

        <!-- <div v-if="shownStage.minPoints" class="is-size-7 has-text-grey mb-2">Players automatically move to the next chapter once they reach {{shownStage.minPoints}} points.</div> -->
        <div class="">
          <draggable v-model="shownStage.challenges" :disabled="shownChallenge.challengeIndex !== null">
            <div v-for="(challenge, index1) in shownStage.challenges" :key="index1">
              <div class="is-flex is-align-items-start">
                <i v-if="!shownStage.completeChallengesInOrder && index1 > 0 && shownStage.challenges[index1 - 1].hideNextChallenge" class="is-size-4 has-text-grey-light fas fa-level-up-alt fa-rotate-90 mt-2 ml-2 mr-3"></i>
                <div class="main-box task-box is-flex-grow-1" :class="{
                  'has-background-danger-dark': errorTasks[shownStageIndex] && errorTasks[shownStageIndex].includes(index1),
                  'mb-1': !shownStage.completeChallengesInOrder
                }">
                  <div class="is-flex is-justify-content-space-between is-align-items-start mb-0">
                    <p
                    @click="expandChallenge(shownStageIndex, index1)"
                    class="is-clickable is-size-6 has-text-weight-semibold" style="overflow-wrap: break-word;">{{ challenge.name ? challenge.name : '[task name not set]' }}</p>
                    <div class="level-right">
                      <div class="level-item">
                        <div
                          v-if="shownStage.challenges.length > 1"
                          @click="deleteChallenge(shownStageIndex, index1)"
                          class="button is-danger is-light is-small is-rounded ml-2"
                        >
                          <i class="fas fa-trash is-clickable"></i>
                        </div>
                        <div @click="changeChallengeSequence(shownStageIndex, index1, -1)" v-if="!(shownStageIndex === 0 && index1 === 0)" class="button is-small is-rounded ml-2">
                          <i class="fas fa-arrow-up fa-xs"></i>
                        </div>
                        <div @click="changeChallengeSequence(shownStageIndex, index1, 1)" v-if="!(shownStageIndex === stages.length -1 && index1 === shownStage.challenges.length - 1)" class="button is-small is-rounded ml-2">
                          <i class="fas fa-arrow-down fa-xs"></i>
                        </div>
                        <div @click="duplicateChallenge(shownStageIndex, index1)" class="button is-small is-rounded ml-2">
                          <i class="fas fa-copy fa-xs"></i>
                        </div>
                        <div @click="expandChallenge(shownStageIndex, index1)" class="button is-primary is-light is-small is-rounded ml-2">
                          <i class="fas fa-edit fa-xs mr-2"></i>
                          Edit
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="expandChallenge(shownStageIndex, index1)"  class="is-clickable mt-1">
                    <span v-if="challenge.points" class="tag is-rounded ml-1">{{ challenge.points }} pts</span>
                    <span v-if="challenge.type" class="tag is-rounded ml-1">{{ challenge.type.charAt(0).toUpperCase() + challenge.type.slice(1) }}</span>
                    <span v-if="challenge.item" class="tag is-rounded ml-1">Has item</span>
                    <span v-if="challenge.customCompletionMessage" class="tag is-rounded ml-1">Has completion message</span>
                    <span v-if="challenge.customFailureMessage" class="tag is-rounded ml-1">Has failure message</span>
                    <span v-if="challenge.hint" class="tag is-rounded ml-1">Has clue</span>
                    <span v-if="challenge.accessCodeRequired" class="tag is-rounded ml-1">Require access code scan</span>
                    <span v-if="challenge.timeRestricted" class="tag is-rounded ml-1">Time-fenced</span>
                    <span v-if="challenge.hideNextChallenge" class="tag is-rounded ml-1">Hides next task</span>
                    <span v-if="challenge.skippable" class="tag is-rounded ml-1">Skippable</span>
                    <span v-if="challenge.wrongAnswerPenalty" class="tag is-rounded ml-1">Pts penalty: {{ challenge.wrongAnswerPenalty }}</span>
                    <span v-if="challenge.timePenalty" class="tag is-rounded ml-1">Time penalty: {{ challenge.timePenalty }} secs</span>
                    <span v-if="challenge.loseXPointsPerMin && challenge.loseXPointsPerXSecs" class="tag is-rounded ml-1">Lose {{ challenge.loseXPointsPerMin }} pts/{{ challenge.loseXPointsPerXSecs }} sec</span>
                    <span v-else-if="challenge.loseXPointsPerMin" class="tag is-rounded ml-1">Lose {{ challenge.loseXPointsPerMin }} pts/min</span>
                    <span v-if="challenge.limitToXTries" class="tag is-rounded ml-1">Max {{ challenge.limitToXTries }} {{ challenge.limitToXTries > 1 ? 'tries' : 'try' }}</span>
                    <span v-if="challenge.isPositionBased" class="tag is-rounded ml-1">Location-based</span>
                    <span v-if="challenge.taskCategory" class="tag is-rounded ml-1">Cat.: {{ challenge.taskCategory }}</span>
                  </div>
                  <div v-if="shownChallenge.stageIndex === shownStageIndex && shownChallenge.challengeIndex === index1" :key="shownChallenge.toString()" class="modal is-active">
                    <div @click="expandChallenge(null, null)" class="modal-background is-clickable"></div>
                    <div class="modal-card task-body-modifier">
                      <header class="modal-card-head">
                        <p class="modal-card-title has-text-weight-semibold">
                        {{ challenge.name }}
                        <span v-if="challenge.points" class="has-text-grey"> {{ challenge.points }} points</span></p>
                        <div class="is-flex is-align-items-center">
                          <!-- <FaqAiChat /> -->
                          <button @click="expandChallenge(null, null)" class="delete" aria-label="close"></button>
                        </div>
                      </header>
                      <div v-if="!challenge.type" style="overflow: scroll;">
                        <section class="modal-card-body px-5 pt-3">
                          <div class="container has-text-centered mt-2">
                            <div class="is-size-5 has-text-weight-bold mb-2" for="">Select a Task Type</div>
                          </div>

                          <div class="tabs is-justify-content-center mb-5">
                            <ul>
                              <li :class="{'is-active': !openedTaskTypeTab}" @click="openedTaskTypeTab = null"><a class="has-text-black" style="text-decoration: none;">Most Popular</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'Text'}" @click="openedTaskTypeTab = 'Text'"><a class="has-text-black" style="text-decoration: none;">Text</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'Number'}" @click="openedTaskTypeTab = 'Number'"><a class="has-text-black" style="text-decoration: none;">Number</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'Photo'}" @click="openedTaskTypeTab = 'Photo'"><a class="has-text-black" style="text-decoration: none;">Photo</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'Video'}" @click="openedTaskTypeTab = 'Video'"><a class="has-text-black" style="text-decoration: none;">Video</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'Puzzle'}" @click="openedTaskTypeTab = 'Puzzle'"><a class="has-text-black" style="text-decoration: none;">Puzzle</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'GPS'}" @click="openedTaskTypeTab = 'GPS'"><a class="has-text-black" style="text-decoration: none;">GPS</a></li>
                              <li :class="{'is-active': openedTaskTypeTab === 'Others'}" @click="openedTaskTypeTab = 'Others'"><a class="has-text-black" style="text-decoration: none;">Others</a></li>
                            </ul>
                          </div>

                          <div class="is-flex is-flex-wrap-wrap">
                            <div
                            v-for="taskType in taskTypeSelectionsxxxxxx.filter(task => task.categories.includes(openedTaskTypeTab))"
                            :key="taskType.type"
                            class="card is-clickable mb-2 mr-3"
                            style="width: 250px;"
                            @click="handleChangeTaskTypeClick(taskType.type, shownStageIndex, index1)"
                            >
                              <div class="card-content p-3">
                                <div class="media mb-2">
                                  <div class="media-content is-flex">
                                    <i v-if="taskType.icon" :class="`${taskType.icon} mr-2`"></i>
                                    <p class="title is-6">{{ taskType.type.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()).replace('Ai', 'AI') }}</p>
                                  </div>
                                </div>

                                <div class="content">
                                  {{ taskType.desc }}
                                </div>
                              </div>
                            </div>
                            <div
                            v-if="['Others', null].includes(openedTaskTypeTab)"
                            class="card is-clickable mb-2 mr-3"
                            style="width: 250px;"
                            @click="copyChallengesChallenge = challenge"
                            >
                              <div class="card-content p-3">
                                <div class="media mb-2">
                                  <div class="media-content is-flex">
                                    <i class="fas fa-clone mr-2"></i>
                                    <p class="title is-6">Duplicate</p>
                                  </div>
                                </div>

                                <div class="content">
                                  Duplicate a task from any other game that you have created
                                </div>
                              </div>
                            </div>
                          </div>
<!--
                          <div v-for="category in taskTypeSelectionsxxxxxxssssssssssssssssssss" :key="category.name">
                            <div class="is-size-5 has-text-weight-bold has-text-dark mb-2" for="">
                              <i :class="`${category.icon} mr-1`"></i>
                              {{ category.name }}
                            </div>
                            <div class="is-flex is-flex-wrap-wrap mb-5"> -->
                              <!-- <div
                              v-if="category.name === 'Others'"
                              class="card is-clickable mb-2 mr-3"
                              style="width: 250px;"
                              @click="copyChallengesChallenge = challenge"
                              >
                                <div class="card-content p-3">
                                  <div class="media mb-2">
                                    <div class="media-content">
                                      <p class="title is-6">Duplicate</p>
                                    </div>
                                  </div>

                                  <div class="content">
                                    Allows you to duplicate a task from any other game(s) that you have created
                                  </div>
                                </div>
                              </div> -->
                              <!-- <div
                              v-for="taskType in category.data"
                              :key="taskType.type"
                              class="card is-clickable mb-2 mr-3"
                              style="width: 250px;"
                              @click="handleChangeTaskTypeClick(taskType.type, shownStageIndex, index1)"
                              >
                                <div class="card-content p-3">
                                  <div class="media mb-2">
                                    <div class="media-content is-flex">
                                      <i v-if="taskType.icon" :class="`${taskType.icon} mr-2`"></i>
                                      <p class="title is-6">{{ taskType.type.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()).replace('Ai', 'AI') }}</p>
                                    </div>
                                  </div>

                                  <div class="content">
                                    {{ taskType.desc }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                          <article v-if="openedTaskTypeTab == 'GPS'" class="message is-warning mt-2">
                            <div class="message-body">
                              <i class="fas fa-lightbulb mr-2" />
                              Relying on GPS location can be unrealiable, as (1) many players block location access, (2) if near high-rise buildings or (3) some phones do not give accurate GPS location.
                              <br><br>
                              <i class="fas fa-external-link-alt mr-2" />
                              <a href="https://www.playtours.app/post/alternatives-to-using-gps-check-ins-for-your-playtours-games" target="_blank">Read this article</a> instead for great alternatives to using GPS location.
                            </div>
                          </article>
                        </section>
                      </div>
                      <div v-if="challenge.type" style="overflow: scroll;">
                        <section class="has-background-white p-0">
                          <div class="tabs is-justify-content-center">
                            <ul>
                              <li :class="{'is-active': !shownChallenge.tabOpened}" @click="changeTaskTab(null)"><a style="text-decoration: none; color: #4a4a4a;">Details</a></li>
                              <li :class="{'is-active': shownChallenge.tabOpened === 'After Task is Completed'}" @click="changeTaskTab('After Task is Completed')"><a style="text-decoration: none; color: #4a4a4a;">After Task is Completed</a></li>
                              <li :class="{'is-active': shownChallenge.tabOpened === 'Penalties'}" @click="changeTaskTab('Penalties')"><a style="text-decoration: none; color: #4a4a4a;">Penalties</a></li>
                              <li :class="{'is-active': shownChallenge.tabOpened === 'Clues & Skipping'}" @click="changeTaskTab('Clues & Skipping')"><a style="text-decoration: none; color: #4a4a4a;">Clues & Skipping</a></li>
                              <li :class="{'is-active': shownChallenge.tabOpened === 'Access & Visibility'}" @click="changeTaskTab('Access & Visibility')"><a style="text-decoration: none; color: #4a4a4a;">Access & Visibility</a></li>
                              <li v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')" :class="{'is-active': shownChallenge.tabOpened === 'Powerups'}" @click="changeTaskTab('Powerups')"><a style="text-decoration: none; color: #4a4a4a;">Powerups</a></li>
                              <li v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')" :class="{'is-active': shownChallenge.tabOpened === 'Powerups2'}" @click="changeTaskTab('Powerups2')"><a style="text-decoration: none; color: #4a4a4a;">Powerups2</a></li>
                            </ul>
                          </div>
                        </section>
                        <section class="modal-card-body px-5 pt-3">
                          <div v-if="!shownChallenge.tabOpened">
                            <div class="mt-5">
                              <FormInput :title="'Name'" :helperImage="screenshots[10]" :mandatory="true" :type="'text'" :value="challenge.name" v-on:update="challenge.name = $event" :errorMessage="getErrorMessage('name', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                              <div class="columns is-mobile">
                                <div class="column">
                                  <label class="label mb-1">Task Type*</label>
                                  <input
                                    :value="challenge.type.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase()).replace('Ai', 'AI')"
                                    @click="handleTaskTypeChange(challenge, 'taskType' + index1)"
                                    @focus="handleTaskTypeChange(challenge, 'taskType' + index1)"
                                    class="input"
                                    :ref="'taskType' + index1"
                                    type="text"
                                  >
                                  <span class="help is-size-7 has-text-grey mt-1 mb-1">Click here for video tutorial | Points gained from completing this task.</span>
                                  <div class="has-text-danger" v-if="getErrorMessage('type', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })">
                                    {{ getErrorMessage('type', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 }) }}
                                  </div>
                                </div>
                                <div class="column pt-1">
                                  <FormInput :title="'Points'" :unit="'point(s)'" :helperImage="screenshots[10]" :helptext="'Points gained from completing this task.'" :videoTutorialYoutubeId="'V62joJYdrIo'" :type="'integer'" :mandatory="true" :value="challenge.points" v-on:update="challenge.points = $event" :errorMessage="getErrorMessage('points', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                                </div>
                                <div class="column pt-1" v-if="shownStage.challenges.some(x => x.taskCategory)">
                                  <FormInput :title="'Task category'" :helptext="'Allow players to filter tasks by categories. Useful if there are too many tasks in 1 chapter.'" :type="'text'" :value="challenge.taskCategory" v-on:update="challenge.taskCategory = $event" :errorMessage="getErrorMessage('task_category', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                                  <TaskCategoriesManager :stages="stages" :stageIndex="shownStageIndex" :challengeIndex="index1" @update-task-category="updateTaskCategory" />
                                </div>
                              </div>
                            </div>
                            <FormInput :title="'Task text'" :key="shownChallenge.challengeIndex" :type="'markdown'" :value="challenge.briefing" v-on:update="challenge.briefing = $event" :errorMessage="getErrorMessage('briefing', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <!-- <FormInput :title="'Type'" :mandatory="true" :type="'selection'" :value="challenge.type" :selections="taskTypeSelectionsxxxxxxsss" v-on:update="challenge.type = $event; resetAnswers(shownStageIndex, index1, $event)"/> -->
                            <article v-if="challenge.type.includes('video')" class="message is-warning">
                              <div class="message-body">
                                To prevent abuse, video size is limited to 140MB. Players are encouraged to use the "Take Video" selection. This should allow a typical iPhone to upload a video that is almost 1min 30s seconds long.
                                <br>
                                <img class="mt-2" style="max-width: 300px;" src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2Fvideo-warning-click-take-video.jpg?alt=media&token=b43c5974-8343-4138-816a-1efb219cbeae" alt="">
                              </div>
                            </article>
                            <article v-if="challenge.type === 'qrbarcode'" class="message">
                              <div class="message-body">
                                <a href="https://www.playtours.app/post/how-to-use-the-qrbarcode-type-task" target="_blank">Click here</a> to learn more about how QRcode/barcode scanning work on PlayTours.
                              </div>
                            </article>
                            <article v-if="challenge.type === 'image-similarity'" class="message">
                              <div class="message-body">
                                <a href="https://www.playtours.app/post/how-to-use-the-image-similarity-type-task" target="_blank">Click here</a> to learn more about how image similarity scanning work on PlayTours.
                              </div>
                            </article>
                            <div v-if="challenge.type" class="">
                              <label
                                v-if="['matching-pairs'].includes(challenge.type)"
                                class="label is-size-6 mb-0 mt-4"
                              >
                              Pairs to match
                              </label>
                              <label
                                v-else-if="['word-search'].includes(challenge.type)"
                                class="label is-size-6 mb-0 mt-4"
                              >
                              Words to search (up to 15 words)
                              </label>
                              <label
                                v-else-if="['direction', 'fill-in-the-blanks', 'scrambled-phrase'].includes(challenge.type)"
                                class="label is-size-6 mb-0 mt-4"
                              >
                              Accepted Answer
                              </label>
                              <div v-else-if="!['free-text', 'image', 'image-multiple', 'video', 'audio', 'file', 'text-share', 'image-share', 'free-location'].includes(challenge.type)">
                                <label class="label is-size-6 mb-0 mt-4">Accepted Answers</label>
                                <p class="help is-size-6 has-text-grey mt-0">
                                  There can be more than 1 accepted answer for this task type.
                                </p>
                              </div>
                              <div v-if="challenge.type === 'free-multiple-choice'">
                                <p class="help is-size-6 has-text-grey mt-0">
                                  Players can select 1 to as many options below. Any submission will be marked as correct. Typically useful for surveys.
                                </p>
                              </div>
                              <div v-if="['free-text', 'image', 'image-multiple', 'video', 'audio', 'file', 'free-location'].includes(challenge.type)">
                                <div class="container mt-2">
                                  <div class="is-size-6 has-text-grey has-text-weight-bold">All answers will be marked as correct automatically. Useful for tasks that are "just for fun" or has no correct/wrong answers.</div>
                                </div>
                              </div>
                              <div v-else-if="['text-share', 'image-share'].includes(challenge.type)">
                                <div class="container mt-2">
                                  <div class="is-size-6 has-text-grey has-text-weight-bold">All answers will be marked as correct automatically. Answer will also be shared with all other teams/players in the same session, viewable on the "Chat" page.</div>
                                </div>
                              </div>
                              <div v-else-if="['judged-image', 'judged-video', 'judged-text', 'judged-audio', 'judged-file'].includes(challenge.type)">
                                <div class="container">
                                  <div class="is-size-6 has-text-grey mb-2">No answers needed, facilitators will mark answers as correct or wrong from the facilitator page.</div>
                                  <iframe width="560" height="315" src="https://www.youtube.com/embed/WbMfAO7lwyw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                              </div>
                              <div v-else-if="['code-input'].includes(challenge.type)">
                                <div class="container">
                                  <div class="is-size-6 has-text-grey">No answers needed, players will see a text input to key in code that will search for items using the Scan Code.</div>
                                </div>
                              </div>
                              <div v-else>
                                <div v-if="['multiple-choice'].includes(challenge.type)">
                                  <div class="container mb-2">
                                    <div class="is-size-6 has-text-grey">Players must select all correct answers to pass, unless the answer is a solo answer.</div>
                                  </div>
                                </div>
                                <div v-if="['image-similarity'].includes(challenge.type)">
                                  <div class="container mb-2">
                                    <div class="is-size-6 has-text-grey">Players must upload an image that is similar than at least 1 of the photos you have chosen below.</div>
                                  </div>
                                </div>
                                <div class="py-2 mb-1" v-for="(answer, index2) in challenge.answers" :key="index2">
                                  <div class="is-flex is-justify-content-flex-end">
                                    <button
                                      v-if="challenge.answers.length > 1"
                                      @click="deleteAnswer(shownStageIndex, index1, index2)"
                                      class="button has-text-weight-semibold is-small is-danger is-rounded is-outlined ml-2 mb-2"
                                    >
                                      <i class="fas fa-trash is-clickable"></i>
                                    </button>
                                  </div>
                                  <div v-if="challenge.type === 'no-answer' && answer.buttonText !== undefined">
                                    <FormInput :title="'Button Text'" helptext="There is no answer required for this task type. Players simply have to click a button to acknowledge that they have read the task text. The Button Text will appear on this button." :type="'text'" :value="answer.buttonText" v-on:update="answer.buttonText = $event" :errorMessage="getErrorMessage('buttonText', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                  </div>
                                  <div v-if="challenge.type === 'text'">
                                    <FormInput :title="'Answer'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                    <div class="answer-options-container-modifier">
                                      <FormInput :title="'Ignore capitalisation'" helptext="If the correct answer is 'Times Square', then 'times square' will also be accepted." :type="'boolean'" :value="answer.isCaseInsensitive" v-on:update="$set(answer, 'isCaseInsensitive', $event)" :errorMessage="getErrorMessage('isCaseInsensitive', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                      <FormInput :title="'Ignore special characters'" helptext="Will also ignore spaces. If the correct answer is 'My Friend's!', then 'myfriends' will also be accepted." :type="'boolean'" :value="answer.isSpecialCharacterInsensitive" v-on:update="$set(answer, 'isSpecialCharacterInsensitive', $event)" :errorMessage="getErrorMessage('isCaseInsensitive', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                      <FormInput :title="'Extra lenient'" helptext="Will check answers by matching letters, regardless of their position, words such as 'is'/'was'/'the', etc will be ignored and allow a 10% error rate. If the answer is 'The Times Square', then 'Timesquaer' or 'Squaretiems' will be accepted" :type="'boolean'" :value="answer.isLenient" v-on:update="$set(answer, 'isLenient', $event)" :errorMessage="getErrorMessage('isLenient', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                      <FormInput :title="'Extra Points'" helptext="Extra points that are given if the answer is this option. Impacts the points in the leaderboard but not the points to complete a chapter." :type="'integer'" :value="answer.extra_points" v-on:update="answer.extra_points = $event" :errorMessage="getErrorMessage('extra_points', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                    </div>
                                  </div>
                                  <div v-if="challenge.type === 'text-ai'">
                                    <FormInput :title="'Prompt'" :type="'text'" helptext="Describe what the answer should be. For example, if you want a poem that rhymes, then describe it as 'A poem that rhymes'. If it has to be a positive-sounding greeting, then describe it as 'A positive sounding greeting to friends'. We use ChatGPT, and there could be very rare cases of false positives where a wrong answer is marked as correct, depending on your prompt." :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                  </div>
                                  <div v-if="challenge.type === 'fill-in-the-blanks'">
                                    <FormInput :title="'Answer'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                    <div class="answer-options-container-modifier">
                                      <FormInput :title="'Ignore capitalisation'" helptext="If the correct answer is 'Times Square', then 'times square' will also be accepted." :type="'boolean'" :value="answer.isCaseInsensitive" v-on:update="$set(answer, 'isCaseInsensitive', $event)" :errorMessage="getErrorMessage('isCaseInsensitive', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                      <FormInput :title="'Ignore special characters'" helptext="Will also ignore spaces. If the correct answer is 'My Friend's!', then 'myfriends' will also be accepted." :type="'boolean'" :value="answer.isSpecialCharacterInsensitive" v-on:update="$set(answer, 'isSpecialCharacterInsensitive', $event)" :errorMessage="getErrorMessage('isCaseInsensitive', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                    </div>
                                  </div>
                                  <div v-if="challenge.type === 'word-search'">
                                    <FormInput :title="'Word'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                    <FormInput :title="'Hide'" helptext="By default, the words required to be searched are shown." :type="'boolean'" :value="answer.isHidden" v-on:update="$set(answer, 'isHidden', $event)" :errorMessage="getErrorMessage('isHidden', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                  </div>
                                  <div v-if="challenge.type === 'matching-pairs'" class="level is-mobile">
                                    <FormInput :title="'Text / Image (Left)'" :type="'image-url'" :value="answer.value" v-on:update="answer.value = $event"/>
                                    <FormInput :title="'Text / Image (Right)'" :type="'image-url'" :value="answer.value1" v-on:update="answer.value1 = $event"/>
                                  </div>
                                  <div v-if="challenge.type === 'scrambled-phrase'">
                                    <FormInput :title="'Answer'" helptext="Phrase will be scrambled by spaces between words. For example: 'This is a dog.' will be scrambled to 'is dog. This a'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="qrbarcode"/>
                                  </div>
                                  <div v-if="challenge.type === 'image-jigsaw'">
                                    <FormInput :title="'Image'" :type="'image-url'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                    <FormInput :title="'No of horizontal pieces'" helptext="Minimum 2 pieces. The number of vertical pieces will be the same as this." :type="'integer'" :value="answer.noOfXPieces" v-on:update="answer.noOfXPieces = $event" :errorMessage="getErrorMessage('noOfXPieces', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                    <!-- <FormInput :title="'No of vertical pieces'" helptext="Minimum 2 pieces" :type="'integer'" :value="answer.noOfYPieces" v-on:update="answer.noOfYPieces = $event" :errorMessage="getErrorMessage('noOfYPieces', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/> -->
                                    <FormInput :title="'Show if piece is at the correct place'" helptext="By default, once a piece is at the right place, there will be a green box around it to indicate correctness" :type="'boolean'" :value="answer.showCorrectPieces" v-on:update="$set(answer, 'showCorrectPieces', $event)" :errorMessage="getErrorMessage('showCorrectPieces', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                  </div>
                                  <div v-if="challenge.type === 'qrbarcode'">
                                    <FormInput :title="'Value'" :type="'text'" :value="answer.value" :showQr="true" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="qrbarcode"/>
                                    <FormInput :title="'Extra Points'" helptext="Extra points that are given if the answer is this option. Impacts the points in the leaderboard but not the points to complete a chapter." :type="'integer'" :value="answer.extra_points" v-on:update="answer.extra_points = $event" :errorMessage="getErrorMessage('extra_points', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                  </div>
                                  <div v-if="challenge.type === 'number'" class="answer-options-container-modifier">
                                    <FormInput :title="'Answer'" :type="'number'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" class="mr-2"/>
                                    <FormInput :title="'Positive Lenience'" :helptext="'How much higher it can go from the value'" :type="'number'" :value="answer.positive_lenience" v-on:update="answer.positive_lenience = $event" :errorMessage="getErrorMessage('positive_lenience', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" class="mr-2"/>
                                    <FormInput :title="'Negative Lenience'" :helptext="'How much lower it can go from the value'" :type="'number'" :value="answer.negative_lenience" v-on:update="answer.negative_lenience = $event" :errorMessage="getErrorMessage('negative_lenience', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" class="mr-2"/>
                                    <FormInput :title="'Extra Points'" helptext="Extra points that are given if the answer is this option. Impacts the points in the leaderboard but not the points to complete a chapter." :type="'integer'" :value="answer.extra_points" v-on:update="answer.extra_points = $event" :errorMessage="getErrorMessage('extra_points', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                  </div>
                                  <div v-if="challenge.type === 'integer'" class="answer-options-container-modifier">
                                    <FormInput :title="'Answer'" :type="'integer'" :value="answer.value" v-on:update="answer.value = $event"/>
                                    <FormInput :title="'Positive Lenience'" :helptext="'How much higher it can go from the value'" :type="'integer'" :value="answer.positive_lenience" v-on:update="answer.positive_lenience = $event" :errorMessage="getErrorMessage('positive_lenience', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                    <FormInput :title="'Negative Lenience'" :helptext="'How much lower it can go from the value'" :type="'integer'" :value="answer.negative_lenience" v-on:update="answer.negative_lenience = $event" :errorMessage="getErrorMessage('negative_lenience', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                    <FormInput :title="'Extra Points'" helptext="Extra points that are given if the answer is this option. Impacts the points in the leaderboard but not the points to complete a chapter." :type="'integer'" :value="answer.extra_points" v-on:update="answer.extra_points = $event" :errorMessage="getErrorMessage('extra_points', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                  </div>
                                  <div v-if="challenge.type === 'multiple-choice'">
                                    <div class="answer-options-mcq-container-modifier">
                                      <FormInput :hideLabel="true" :type="'boolean'" :value="answer.is_valid" v-on:update="$set(answer, 'is_valid', $event)" class="mr-2"/>
                                      <FormInput :hideLabel="true" :mandatory="true" :type="'text'" :value="answer.value" v-on:update="$set(answer, 'value', $event)" style="width: 100%;"/>
                                      <FormInput v-show="answer.is_valid" :title="'Solo Answer'" helptext="If this answer is selected, the entire task will be considered correct, even if there are other correct answers." :type="'boolean'" :value="answer.is_solo_answer" v-on:update="$set(answer, 'is_solo_answer', $event)"/>
                                    </div>
                                  </div>
                                  <div v-if="challenge.type === 'free-multiple-choice'">
                                    <div class="is-flex is-align-items-center">
                                      <FormInput :hideLabel="true" :mandatory="true" :type="'text'" :value="answer.value" v-on:update="answer.value = $event"/>
                                    </div>
                                  </div>
                                  <div v-if="challenge.type === 'direction' && challenge.answers.length === 1">
                                    <LatLonInput
                                    :lat="answer.lat"
                                    :lon="answer.lon" :radius="answer.radius_in_meters"
                                    v-on:updateLatLonRad="$set(challenge.answers, index2, {...answer, lat: $event.lat, lon: $event.lon, radius_in_meters: $event.radius })"
                                    v-on:updateRadius="$set(challenge.answers, index2, {...answer, radius_in_meters: $event })"
                                    v-on:updateLat="$set(challenge.answers, index2, {...answer, lat: $event })"
                                    v-on:updateLon="$set(challenge.answers, index2, {...answer, lon: $event })"
                                    />
                                  </div>
                                  <div v-if="challenge.type === 'location'">
                                    <LatLonInput
                                    :lat="answer.lat"
                                    :lon="answer.lon" :radius="answer.radius_in_meters"
                                    v-on:updateLatLonRad="$set(challenge.answers, index2, {...answer, lat: $event.lat, lon: $event.lon, radius_in_meters: $event.radius })"
                                    v-on:updateRadius="$set(challenge.answers, index2, {...answer, radius_in_meters: $event })"
                                    v-on:updateLat="$set(challenge.answers, index2, {...answer, lat: $event })"
                                    v-on:updateLon="$set(challenge.answers, index2, {...answer, lon: $event })"
                                    />
                                  </div>
                                  <div v-if="challenge.type === 'object-recognition'">
                                    <FormInput :title="'Value'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                  </div>
                                  <div v-if="challenge.type === 'image-similarity'">
                                    <FormInput :title="'Image'" :type="'image-url'" :value="answer.value" v-on:update="answer.value = $event"/>
                                  </div>
                                  <div v-if="challenge.type === 'judged-image-ai'">
                                    <FormInput :title="'Image description'" helptext="Describe what the image uploaded should be in detail. For example: 2 persons smiling at the camera in front of a metal gate." :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="text"/>
                                  </div>
                                  <div v-if="challenge.type === 'audio-words-recognition'">
                                    <FormInput :title="'Value'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })" key="audio-words-recognition"/>
                                  </div>
                                  <div v-if="challenge.type === 'combination-lock'">
                                    <FormInput :title="'Value'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event" :errorMessage="getErrorMessage('value', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                    <FormInput :title="'Lock configuration'" :type="'text'" :value="answer.config" v-on:update="answer.config = $event" :errorMessage="getErrorMessage('config', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1, answerIndex: index2 })"/>
                                  </div>
                                  <FormInput
                                  v-if="uiMods.enableStoryMode"
                                  :title="'Trigger chapter'"
                                  :helptext="answer.triggeredStage ? `Once players meet the minimum points for this chapter, they will go to '${stages[answer.triggeredStage - 1] && stages[answer.triggeredStage - 1].title ? stages[answer.triggeredStage - 1].title : `Chapter ${answer.triggeredStage}`}' next.` : `Once players meet the minimum points for this chapter, they will go to the triggered chapter next.`"
                                  :type="'integer'" :value="answer.triggeredStage" v-on:update="$set(answer, 'triggeredStage', $event)"/>
                                  <hr
                                    v-if="challenge.answers.length > 1"
                                    class="my-0"
                                    style="background-color: #e8e8e8;"
                                  >
                                </div>
                                <div v-if="!(['no-answer', 'direction', 'fill-in-the-blanks', 'scrambled-phrase', 'image-jigsaw', 'combination-lock', 'judged-image-ai', 'free-text', 'image', 'image-multiple', 'video', 'audio', 'file'].includes(challenge.type) && challenge.answers.length === 1) && !(challenge.type === 'word-search' && challenge.answers.length > 14)" class="container has-text-centered mt-2">
                                  <button
                                    @click="addToAnswersArray(shownStageIndex, index1)"
                                    class="button has-text-weight-semibold is-primary is-rounded is-small mt-0"
                                  >
                                    <i class="fas fa-plus is-clickable mr-2"></i>
                                      {{ challenge.answers.length > 1 ? 'Add More Possible Answers' : 'Add Possible Answer' }}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <hr>
                            <article v-if="challenge.isPositionBased" class="message is-warning mb-0 mt-4">
                              <div class="message-body">
                                <i class="fas fa-lightbulb mr-2" />
                                Relying on GPS location can be unrealiable, as (1) many players block location access, (2) if near high-rise buildings or (3) some phones do not give accurate GPS location.
                                <br><br>
                                <i class="fas fa-external-link-alt mr-2" />
                                The great thing about using location-based task is that there will be a map shown above the task list with all the locations for the different location-based tasks.
                                If you do not need this, but still want players to prove that they are at a specific location before they complete a task, <a href="https://www.playtours.app/post/how-to-make-the-most-of-playtours-task-categories-and-hide-next-task-features" target="_blank">read our guide</a> on how to link 2 tasks together.
                              </div>
                            </article>
                            <FormInput :title="'Location-based'" :helptext="'If yes, players must be within radius to complete task.'"  :videoTutorialYoutubeId="'kMT6sgKNij0'" :type="'boolean'" :value="challenge.isPositionBased" v-on:update="togglePositionBasedTask(shownStageIndex, index1, $event)" :errorMessage="getErrorMessage('isPositionBased', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <LatLonInput
                            class="ml-5"
                            v-if="challenge.position"
                            :helptext="''"
                            :lat="challenge.position.lat" :lon="challenge.position.lon"
                            :radius="challenge.position.radius_in_meters"
                            v-on:updateLatLonRad="$set(shownStage.challenges, index1, {...challenge, position: { lat: $event.lat, lon: $event.lon, radius_in_meters: $event.radius, shownOnMap: challenge.position.shownOnMap, visibleOutsideRadius: challenge.position.visibleOutsideRadius, allowCompletionOutsideRadius: challenge.position.allowCompletionOutsideRadius }})"
                            v-on:updateRadius="$set(shownStage.challenges, index1, {...challenge, position: { lat: challenge.position.lat, lon: challenge.position.lon, radius_in_meters: $event, shownOnMap: challenge.position.shownOnMap, visibleOutsideRadius: challenge.position.visibleOutsideRadius, allowCompletionOutsideRadius: challenge.position.allowCompletionOutsideRadius }})"
                            v-on:updateLat="$set(shownStage.challenges, index1, {...challenge, position: { lat: $event, lon: challenge.position.lon, radius_in_meters: challenge.position.radius_in_meters, shownOnMap: challenge.position.shownOnMap, visibleOutsideRadius: challenge.position.visibleOutsideRadius, allowCompletionOutsideRadius: challenge.position.allowCompletionOutsideRadius }})"
                            v-on:updateLon="$set(shownStage.challenges, index1, {...challenge, position: { lat: challenge.position.lat, lon: $event, radius_in_meters: challenge.position.radius_in_meters, shownOnMap: challenge.position.shownOnMap, visibleOutsideRadius: challenge.position.visibleOutsideRadius, allowCompletionOutsideRadius: challenge.position.allowCompletionOutsideRadius }})"
                            />
                            <FormInput class="ml-5" v-if="challenge.position" :title="'Can view task outside radius'" :helptext="!challenge.position.allowCompletionOutsideRadius ? 'Players must still be within the radius to complete the task' : ''" :videoTutorialYoutubeId="'kMT6sgKNij0'" :type="'boolean'" :value="challenge.position.visibleOutsideRadius" v-on:update="$set(challenge.position, 'visibleOutsideRadius', $event)"/>
                            <FormInput class="ml-5" v-if="challenge.position && challenge.position.visibleOutsideRadius" :title="'Can complete task outside radius'" helptext="Players can complete the task outside the radius. This reduces player frustration in the event where their GPS location is not working well." :type="'boolean'" :value="challenge.position.allowCompletionOutsideRadius" v-on:update="$set(challenge.position, 'allowCompletionOutsideRadius', $event)"/>
                            <FormInput class="ml-5" v-if="challenge.position" :title="'Show on map'" :helptext="'Show task on map to show players where to go.'" :videoTutorialYoutubeId="'kMT6sgKNij0'" :type="'boolean'" :value="challenge.position.shownOnMap" v-on:update="challenge.position.shownOnMap = $event"/>
                            <FormInput class="ml-5" v-if="challenge.position && challenge.position.shownOnMap" :title="'Custom map icon'" helptext="Optional. Add a custom image as the icon on the map." :type="'image-url'" :value="challenge.position.mapIconUrl" v-on:update="$set(challenge.position, 'mapIconUrl', $event)"/>
                          </div>
                          <div v-show="shownChallenge.tabOpened === 'After Task is Completed'">
                            <FormInput :title="'Custom text after answering correctly'" :type="'boolean'" :videoTutorialYoutubeId="'G31lZDDp-_Y'" :helptext="'Upon completion, this additional information will be shown to players.'" :value="challenge.customCompletionMessage || challenge.hasCustomCompletionMessage" v-on:update="toggleCompletionMessage(shownStageIndex, index1, $event)"/>
                            <div class="ml-5" v-if="challenge.hasCustomCompletionMessage || challenge.customCompletionMessage">
                              <div class="mb-3">
                                <FormInput :title="''" :type="'markdown'" :value="challenge.customCompletionMessage" v-on:update="challenge.customCompletionMessage = $event" :errorMessage="getErrorMessage('customCompletionMessage', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                              </div>
                            </div>
                            <FormInput :title="'Custom text after answering wrongly'" :type="'boolean'" :helptext="'Upon a wrong answer, this additional information will be shown to players.'" :value="challenge.customFailureMessage || challenge.hasCustomFailureMessage" v-on:update="toggleFailureMessage(shownStageIndex, index1, $event)"/>
                            <div class="ml-5" v-if="challenge.hasCustomFailureMessage || challenge.customFailureMessage">
                              <div class="mb-3">
                                <FormInput :title="''" :type="'markdown'" :value="challenge.customFailureMessage" v-on:update="challenge.customFailureMessage = $event" :errorMessage="getErrorMessage('customFailureMessage', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                              </div>
                            </div>
                            <div v-if="['judged-image', 'judged-video', 'judged-text'].includes(challenge.type)">
                              <FormInput :title="'Custom text while player waits for judgement'" :type="'boolean'" :helptext="'While waiting for judgement, this additional information will be shown to players.'" :value="challenge.customJudgementMessage || challenge.hasCustomJudgementMessage" v-on:update="toggleJudgementMessage(shownStageIndex, index1, $event)"/>
                              <div class="ml-5" v-if="challenge.hasCustomJudgementMessage || challenge.customJudgementMessage">
                                <div class="mb-3">
                                  <FormInput :title="''" :type="'markdown'" :value="challenge.customJudgementMessage" v-on:update="$set(challenge, 'customJudgementMessage', $event)" :errorMessage="getErrorMessage('customJudgementMessage', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                                </div>
                              </div>
                            </div>
                            <FormInput :title="'Get item upon completion'" :type="'boolean'" :videoTutorialYoutubeId="'TmRRSo_PvE8'" :helptext="'Upon completion, an item will be added to the team inventory.'" :value="challenge.item || challenge.hasItem" v-on:update="toggleHasItem(shownStageIndex, index1, $event)"/>
                            <FormInput class="ml-5" v-if="challenge.hasItem || challenge.item" :title="'Item'" :helptext="'Item added to team\'s inventory if this task is completed'" :type="'markdown'" :value="challenge.item" v-on:update="challenge.item = $event" :errorMessage="getErrorMessage('item', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.hasItem || challenge.item" :title="'Item Category'" :helptext="'Players can filter items in the inventory by category'" :type="'text'" :value="challenge.itemCategory" v-on:update="challenge.itemCategory = $event" :errorMessage="getErrorMessage('itemCategory', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="uiMods.useItemOrder && (challenge.hasItem || challenge.item)" :title="'Item Order'" helptext="Sort items' order in the inventory. For example, item order 3 will always appear above item order 5. Useful if your items need to be logically ordered. Items without configured orders will always appear above items with orders." :type="'number'" :value="challenge.itemOrder" v-on:update="challenge.itemOrder = $event" :errorMessage="getErrorMessage('itemOrder', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <!-- <FormInput v-if="!shownStage.completeChallengesInOrder" :title="'Open next task upon completion'" :type="'boolean'" :helptext="'Upon completion, player will be shown the next task in the task list, if any'" :value="challenge.openNextChallenge" v-on:update="$set(challenge, 'openNextChallenge', $event)"/> -->
                            <FormInput :title="'Hide next task unless this task is completed'" :helptext="`Useful if 'Must complete tasks in order' is disabled but you want to hide a task that is dependent on this.`" :type="'boolean'" :value="challenge.hideNextChallenge" v-on:update="$set(challenge, 'hideNextChallenge', $event)"/>
                          </div>
                          <div v-show="shownChallenge.tabOpened === 'Penalties'">
                            <FormInput :title="'Points penalty'" :unit="'point(s)'" :helptext="'Points deducted from the leaderboard if answered wrongly'" :videoTutorialYoutubeId="'_yVqCwniudA'" :type="'integer'" :value="challenge.wrongAnswerPenalty" v-on:update="challenge.wrongAnswerPenalty = $event" :errorMessage="getErrorMessage('wrong_answer_penalty', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Time penalty'" :unit="'second(s)'" :helperImage="screenshots[16]" :helptext="'The number of seconds that the team must wait after answering this task wrongly'" :videoTutorialYoutubeId="'NXT9KmJXxvM'" :type="'integer'" :value="challenge.timePenalty" v-on:update="challenge.timePenalty = $event" :errorMessage="getErrorMessage('time_penalty', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="`Lose X points per ${`${ !challenge.loseXPointsPerXSecs ? 'minute' : `${challenge.loseXPointsPerXSecs} second(s)`}`}`" :unit="`point(s) per ${ !challenge.loseXPointsPerXSecs ? 'minute' : `${challenge.loseXPointsPerXSecs} second(s)`}`" :helptext="'Points deducted from the leaderboard for every minute that passes, will not deduct more than the number of points assigned to this task'" :videoTutorialYoutubeId="'fXHh6QsZW7g'" :type="'integer'" :value="challenge.loseXPointsPerMin" v-on:update="$set(challenge, 'loseXPointsPerMin', $event)" :errorMessage="getErrorMessage('lose_x_points_per_min', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.loseXPointsPerMin" :title="'Instead of per minute, reduce every X seconds instead'" :unit="'second(s)'" :helptext="'Lose X points per X seconds instead for more granularity.'" :type="'integer'" :min="1" :value="challenge.loseXPointsPerXSecs" v-on:update="$set(challenge, 'loseXPointsPerXSecs', $event)" :errorMessage="getErrorMessage('lose_x_points_per_x_secs', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Limit to X tries'" :unit="challenge.limitToXTries && challenge.limitToXTries > 1 ? 'tries' : 'try'" :helptext="'Task will be automatically skipped after X attempts. Team gains points to move forward in the game but points will not be added to the leaderboard.'" :type="'integer'" :min="1" :value="challenge.limitToXTries" v-on:update="$set(challenge, 'limitToXTries', $event)" :errorMessage="getErrorMessage('limit_to_x_tries', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                          </div>
                          <div v-show="shownChallenge.tabOpened === 'Clues & Skipping'">
                            <FormInput :title="'Skippable'" :helperImage="screenshots[11]" :videoTutorialYoutubeId="'s6kc8RzbpUY'" :helptext="'Points gained from skipping a task will not be added as points to the leaderboard'" :type="'boolean'" :value="challenge.skippable" v-on:update="challenge.skippable = $event" :errorMessage="getErrorMessage('skippable', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <div v-if="challenge.skippable" class=" ml-5 answer-options-container-modifier">
                              <FormInput class="ml-5" :title="'Skippable only after X tries'" :helptext="'Allow players to skip only after trying multiple times'" :type="'integer'" :min="1" :value="challenge.skippableAfterXTries" v-on:update="$set(challenge, 'skippableAfterXTries', $event)" :errorMessage="getErrorMessage('skippable_after_x_tries', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                              <FormInput class="ml-5" :title="'Skippable only after X minutes'" :helptext="'Allow players to skip after X minutes'" :type="'integer'" :min="1" :value="challenge.skippableAfterXMins" v-on:update="$set(challenge, 'skippableAfterXMins', $event)" :errorMessage="getErrorMessage('skippable_after_x_mins', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                              <FormInput class="ml-5" :title="'Skip Penalty'" :helperImage="screenshots[12]" :helptext="'Points deducted from the leaderboard if skipped'" :type="'integer'" :value="challenge.skipPenalty" v-on:update="challenge.skipPenalty = $event" :errorMessage="getErrorMessage('skip_penalty', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            </div>
                            <FormInput :title="'Has clue'" :helperImage="screenshots[13]" :videoTutorialYoutubeId="'CA6_90aZPUE'" :helptext="'Clues help players complete tasks. Allow clues to be bought with coins, appear after X minutes or to appear after X tries'" :type="'boolean'" :value="challenge.hint || challenge.hasHint" v-on:update="toggleHint(shownStageIndex, index1, $event)"/>
                            <FormInput v-if="challenge.hasHint || challenge.hint" :title="'Clue text'" :helperImage="screenshots[15]" :type="'markdown'" :value="challenge.hint.hint" v-on:update="challenge.hint.hint = $event" :errorMessage="getErrorMessage('hint', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <div class="ml-5 answer-options-container-modifier" v-if="challenge.hasHint || challenge.hint">
                                <FormInput :title="'Reveal after X tries'" :helptext="'Reveal hint after X tries, without requiring coins'" :type="'integer'" :min="0" :value="challenge.hint.revealAfterXTries" v-on:update="$set(challenge.hint, 'revealAfterXTries', $event)" :errorMessage="getErrorMessage('reveal_after_x_tries', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                                <FormInput :title="'Reveal after X minutes'" :helptext="'Reveal hint after X minutes, without requiring coins'" :type="'integer'" :min="1" :value="challenge.hint.revealAfterXMins" v-on:update="$set(challenge.hint, 'revealAfterXMins', $event)" :errorMessage="getErrorMessage('reveal_after_x_mins', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                                <FormInput :title="'Clue Penalty'" :helptext="'Points deducted from the leaderboard if clue is used. Leave blank to have no penalty.'" :type="'integer'" :value="challenge.hint.penalty" v-on:update="challenge.hint.penalty = $event"/>
                                <FormInput :title="'Coins Needed'" :helperImage="screenshots[14]" :helptext="'Coins needed to buy hint. Leave blank to disallow purchase using coins, if you are only using the reveal hint feature below.'" :type="'integer'" :value="challenge.hint.credits" v-on:update="challenge.hint.credits = $event" :errorMessage="getErrorMessage('credits', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            </div>
                          </div>
                          <div v-show="shownChallenge.tabOpened === 'Access & Visibility'">
                            <FormInput :title="'Task category'" :helptext="'Allow players to filter tasks by categories. Useful if there are too many tasks in 1 chapter.'" :type="'text'" :value="challenge.taskCategory" v-on:update="challenge.taskCategory = $event" :errorMessage="getErrorMessage('task_category', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <TaskCategoriesManager :stages="stages" :stageIndex="shownStageIndex" :challengeIndex="index1" @update-task-category="updateTaskCategory" />
                            <FormInput :title="'Show only specific players'" :helperImage="screenshots[17]" :helptext="'Example: 1,3,4,6. Show to only specific players by player index. Player index depends on when they joined the team. First player is player 1, and so on. Useful for chapters where some players see 1 thing, and other players see another.'" :type="'text'" :value="challenge.showSpecificPlayersOnly" v-on:update="challenge.showSpecificPlayersOnly = $event" :errorMessage="getErrorMessage('show_specific_players_only', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Require access code scan'" :helptext="'Players must scan a QR/barcode or type in this code to access this task.'" :type="'text'" :value="challenge.accessCode" :showQr="true" v-on:update="$set(challenge, 'accessCode', $event)" :errorMessage="getErrorMessage('access_code', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Limit completions per session'" :unit="'completions per session'" :helptext="'Limit the number of times this task can be completed. Useful for tasks that can only be completed by the first X teams.'" :type="'integer'" :min="1" :value="challenge.maxCompletionsPerSession" v-on:update="challenge.maxCompletionsPerSession = $event" :errorMessage="getErrorMessage('max_completions_per_session', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Allow redo after X minutes'" :unit="'minutes'" :helptext="'Allow re-attempt of task X minutes after completing it. Rarely used, but useful for some types of activities.'" :type="'number'" :min="0.001" :value="challenge.redoAfterXMins" v-on:update="challenge.redoAfterXMins = $event" :errorMessage="getErrorMessage('redo_after_x_mins', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Hide answer and \'Custom text after answering correctly\' if not answered correctly'" :helptext="'By default, the answer is shown to the player even if they skip or used up all their attempts. This will hide it instead.'" :type="'boolean'" :value="challenge.hideAnswerIfNotAnsweredCorrectly" v-on:update="$set(challenge, 'hideAnswerIfNotAnsweredCorrectly', $event)" :errorMessage="getErrorMessage('hide_answer_if_not_answered_correctly', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'No chapter points if not answered correctly'" :helptext="'By default, teams get points for the chapter even if they skip or used up all their attempts. This will do otherwise.'" :type="'boolean'" :value="challenge.noStagePointsIfNotAnsweredCorrectly" v-on:update="$set(challenge, 'noStagePointsIfNotAnsweredCorrectly', $event)" :errorMessage="getErrorMessage('no_stage_points_if_not_answered_correctly', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Hide after completion'" :helptext="'Prevent players from seeing the task contents after completion.'" :type="'boolean'" :value="challenge.hideAfterCompletion" v-on:update="$set(challenge, 'hideAfterCompletion', $event)" :errorMessage="getErrorMessage('hide_after_completion', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Hide next task unless this task is completed'" :helptext="`Useful if 'Must complete tasks in order' is disabled but you want to hide a task that is dependent on this.`" :type="'boolean'" :value="challenge.hideNextChallenge" v-on:update="$set(challenge, 'hideNextChallenge', $event)"/>
                            <FormInput :title="'Restrict task completion within a timeframe'" :helptext="'Prevent tasks from being completed outside of a timeframe.'" :type="'boolean'" :value="challenge.timeRestricted" v-on:update="$set(challenge, 'timeRestricted', $event)" :errorMessage="getErrorMessage('time_restricted', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.timeRestricted" :title="'Allow only after (time)'" :helptext="'Allow task to be completed only after this time. Uses device local time.'" :type="'time'" :value="challenge.timeRestrictedAllowAfter" v-on:update="$set(challenge, 'timeRestrictedAllowAfter', $event)" :errorMessage="getErrorMessage('time_restricted_allow_after', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.timeRestricted" :title="'Allow only after (date)'" :helptext="'Allow task to be completed only on/after this date. Uses device local time.'" :type="'date'" :value="challenge.timeRestrictedAllowAfterDate" v-on:update="$set(challenge, 'timeRestrictedAllowAfterDate', $event)" :errorMessage="getErrorMessage('time_restricted_allow_after_date', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.timeRestricted" :title="'Allow only before (time)'" :helptext="'Allow task to be completed only before this time. Uses device local time.'" :type="'time'" :value="challenge.timeRestrictedAllowBefore" v-on:update="$set(challenge, 'timeRestrictedAllowBefore', $event)" :errorMessage="getErrorMessage('time_restricted_allow_before', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.timeRestricted" :title="'Allow only before (date)'" :helptext="'Allow task to be completed only on/before this date. Uses device local time.'" :type="'date'" :value="challenge.timeRestrictedAllowBeforeDate" v-on:update="$set(challenge, 'timeRestrictedAllowBeforeDate', $event)" :errorMessage="getErrorMessage('time_restricted_allow_before_date', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput class="ml-5" v-if="challenge.timeRestricted" :title="'Hide task outside of timeframe'" type="boolean" :value="challenge.timeRestrictedHideOutsideTimeframe" v-on:update="$set(challenge, 'timeRestrictedHideOutsideTimeframe', $event)"/>
                            <FormInput :title="'Limit teams on this task in shuffling logic'" helptext="This will only be relevant if 'Shuffle task order' is enabled. If the limit is reached, teams will be assigned other tasks instead, unless, they do not have any other tasks remaining." :type="'integer'" :unit="'team(s)'" :value="challenge.limitTeamsInShuffle" v-on:update="$set(challenge, 'limitTeamsInShuffle', $event)"/>
                            <FormInput :title="'Show only after X minutes'" :helptext="'Show this task only after X minutes have passed since the start of the chapter.'" :type="'integer'" :min="0" :value="challenge.showOnlyAfterXMinutes" :unit="'minute(s)'" v-on:update="$set(challenge, 'showOnlyAfterXMinutes', $event)"/>
                            <FormInput :title="'Show only after X chapter points'" :helptext="'Show this task only after the team has accumulated X points in this chapter.'" :type="'integer'" :min="0" :value="challenge.showOnlyAfterXChapterPoints" v-on:update="$set(challenge, 'showOnlyAfterXChapterPoints', $event)" :errorMessage="getErrorMessage('show_only_after_x_chapter_points', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput v-if="challenge.showOnlyAfterXChapterPoints && challenge.showOnlyAfterXChapterPoints > 0" :title="'Consider points only from this task category'" :helptext="'Useful for tasks that depend on specific task categories only.'" :type="'text'" :value="challenge.showOnlyAfterXChapterPointsTaskCategory" v-on:update="$set(challenge, 'showOnlyAfterXChapterPointsTaskCategory', $event)" :errorMessage="getErrorMessage('showOnlyAfterXChapterPointsTaskCategory', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Do not add points to leaderboard'" :type="'boolean'" helptext="Useful if there are tasks that advances the game but should not reward the player." :value="challenge.doNotAddPointsToLeaderboard" v-on:update="$set(challenge, 'doNotAddPointsToLeaderboard', $event)"/>
                            <FormInput :title="'Must be completed to complete this chapter'" :type="'boolean'" helptext="" :value="challenge.mandatory" v-on:update="$set(challenge, 'mandatory', $event)"/>
                            <FormInput :title="'Must complete with X other teams'" :helptext="'During answer submission, the player must select another team(s), and they will share the same submission.'" :type="'integer'" :min="0" :value="challenge.mustCompleteWithXOtherTeams" v-on:update="$set(challenge, 'mustCompleteWithXOtherTeams', $event)" :errorMessage="getErrorMessage('must_complete_with_x_other_teams', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Task List Icon Image'" :type="'image-url'" :value="challenge.taskListIconImage" v-on:update="$set(challenge, 'taskListIconImage', $event)" :errorMessage="getErrorMessage('task_list_icon_image', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                          </div>
                          <div v-show="shownChallenge.tabOpened === 'Powerups'">
                            <FormInput :title="'Powerup text'" :type="'markdown'" :value="challenge.powerupText" v-on:update="$set(challenge, 'powerupText', $event)" :errorMessage="getErrorMessage('powerup_text', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                            <FormInput :title="'Star Power'" :type="'integer'" :value="challenge.powerupStars" v-on:update="$set(challenge, 'powerupStars', $event)" :errorMessage="getErrorMessage('powerup_stars', { fieldName: 'challenges', stageIndex: shownStageIndex, challengeIndex: index1 })"/>
                          </div>
                          <div v-show="shownChallenge.tabOpened === 'Powerups2'">
                            <FormInput v-if="!challenge.powerups" :title="'Enable powerups'" :type="'boolean'" v-on:update="$set(challenge, 'powerups', [{}])"/>
                            <div class="" v-for="(powerup, index2) in challenge.powerups" :key="index2">
                              <div class="is-size-3 has-text-weight-semi-bold">Powerup {{ index2 + 1 }}</div>
                              <FormInput :title="'Powerup text'" :type="'markdown'" :value="powerup.text" v-on:update="$set(powerup, 'text', $event)" />
                              <FormInput :title="'Star power'" :type="'integer'" :value="powerup.stars" v-on:update="$set(powerup, 'stars', $event)" />
                              <FormInput :title="'Time power'" :type="'integer'" :value="powerup.time" v-on:update="$set(powerup, 'time', $event)" />
                              <FormInput :title="'Just say no'" :type="'boolean'" :value="powerup.justSayNo" v-on:update="$set(powerup, 'justSayNo', $event)" />
                              <button
                                @click="challenge.powerups = challenge.powerups.filter((x, i) => i != index2 )"
                                class="button is-danger is-rounded is-outlined mt-2"
                              >
                                Delete powerup {{ index2 + 1 }}
                              </button>
                              <hr>
                            </div>
                            <button
                              v-if="challenge.powerups"
                              @click="challenge.powerups = [...challenge.powerups, {}]"
                              class="button is-primary is-rounded"
                            >
                              Add Powerup
                            </button>
                          </div>
                        </section>
                      </div>
                      <footer class="modal-card-foot">
                        <div class="level is-mobile" style="width: 100%">
                          <div class="level-left">
                            <div class="level-item mr-1">
                              <button
                              :disabled="shownChallenge.challengeIndex === 0"
                              @click="expandChallenge(shownChallenge.stageIndex, shownChallenge.challengeIndex - 1)"
                              class="button is-primary is-outlined">
                              <i class="fas fa-backward mr-2"></i>
                              Prev Task
                              </button>
                            </div>
                            <div class="level-item mr-1">
                              <button
                              :disabled="!(stages[shownChallenge.stageIndex] && stages[shownChallenge.stageIndex].challenges.length !== shownChallenge.challengeIndex+1)"
                              @click="expandChallenge(shownChallenge.stageIndex, shownChallenge.challengeIndex + 1)"
                              class="button is-primary is-outlined">
                              Next Task
                              <i class="fas fa-forward ml-2"></i>
                              </button>
                            </div>
                            <div
                              v-if="!(stages[shownChallenge.stageIndex] && stages[shownChallenge.stageIndex].challenges.length !== shownChallenge.challengeIndex+1)"
                              style="border-left: 2px solid rgb(222, 222, 222); height: 40px;" class="ml-3 mr-4">
                            </div>
                            <div
                              class="level-item mr-1"
                              v-if="!(stages[shownChallenge.stageIndex] && stages[shownChallenge.stageIndex].challenges.length !== shownChallenge.challengeIndex+1)"
                            >
                              <button
                              @click="addToChallengeArray(shownStageIndex, true)"
                              class="button is-primary is-outlined">
                              <i class="fas fa-plus-circle mr-2"></i>
                              Add New
                              </button>
                            </div>
                            <div
                              class="level-item mr-1"
                              v-if="!(stages[shownChallenge.stageIndex] && stages[shownChallenge.stageIndex].challenges.length !== shownChallenge.challengeIndex+1)"
                            >
                              <button
                              @click="duplicateChallenge(shownStageIndex, shownChallenge.challengeIndex, true)"
                              class="button is-primary is-outlined">
                              <i class="fas fa-copy mr-2"></i>
                              Duplicate
                              </button>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <button @click="expandChallenge(null, null)" class="button is-primary">Done</button>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="shownStage.completeChallengesInOrder" class="stage-connector"></div>
            </div>
          </draggable>
          <div class="container mb-4 mx-0">
            <button
              @click="addToChallengeArray(shownStageIndex)"
              class="button has-text-weight-semibold is-primary is-rounded mt-0"
            >
              <i class="fas fa-plus is-clickable mr-2"></i>
                Add task
            </button>
          </div>
        </div>

        <FormInput v-if="shownStage.showMinPoints || shownStage.minPoints !== null" :title="'Points to complete chapter'" :unit="`point(s) | Max ${calculateTotalTaskPoints(shownStageIndex)}`" :helperImage="screenshots[9]" :mandatory="true" :helptext="'Teams go to the next chapter once they meet or exceeds this'" :videoTutorialYoutubeId="'V62joJYdrIo'" :type="'integer'" :value="shownStage.minPoints" v-on:update="shownStage.minPoints = $event" :max="calculateTotalTaskPoints(shownStageIndex)" :errorMessage="getErrorMessage('min_points', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        <article v-if="shownStage.minPoints && calculateTotalTaskPoints(shownStageIndex) > shownStage.minPoints" class="message is-warning">
          <div class="message-body warning-background-animation">
            Players will move to the next chapter once they reach {{ shownStage.minPoints ? shownStage.minPoints : '0' }} points. They may reach that without having to complete all the tasks, which may be intended. Else, to ensure that teams complete all tasks in this chapter, set the points needed to <b>{{ calculateTotalTaskPoints(shownStageIndex) }} points</b>.
          </div>
        </article>

        <!-- <FormInput v-show="shownStage.showCompleteChallengesInOrder" :title="'Must complete tasks in order'" :helptext="'Tasks must be completed from top to bottom. Incomplete tasks not at the top will not be accessible, but their titles will be shown.'" :type="'boolean'" :value="shownStage.completeChallengesInOrder" v-on:update="$set(shownStage, 'completeChallengesInOrder', $event)"/> -->
        <FormInput v-show="shownStage.showTimeLimitInMinutes || shownStage.timeLimitInMinutes" :title="'Time limit'" :unit="'minute(s)'" :helperImage="screenshots[8]" :helptext="'In minutes. Once a team exceeds the time, they can still complete tasks to gain points and advance but points will not be added to the leaderboard'" :videoTutorialYoutubeId="'iX5vN3ANZ_g'" :type="'number'" :value="shownStage.timeLimitInMinutes" v-on:update="shownStage.timeLimitInMinutes = $event" :errorMessage="getErrorMessage('time_limit_in_minutes', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        <div class="ml-5" v-if="shownStage.timeLimitInMinutes && shownStage.timeLimitInMinutes > 0">
          <FormInput :title="'Auto Forward'" :helperImage="screenshots[8]" :helptext="'Team will move to the next chapter when the time is up.'" :type="'boolean'" :value="shownStage.autoForwardWhenTimeIsUp" v-on:update="shownStage.autoForwardWhenTimeIsUp = $event" :errorMessage="getErrorMessage('auto_forward_when_time_is_up', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        </div>
        <FormInput v-show="shownStage.showChapterBackgroundUrl" :title="'Chapter background URL'" :helptext="'Adds a custom background to make the game more immersive.'" :type="'image-url'" :value="shownStage.chapterBackgroundUrl" v-on:update="shownStage.chapterBackgroundUrl = $event" :errorMessage="getErrorMessage('chapter_background_url', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        <FormInput v-if="uiMods.enableTaskCategoryMinimumCompletions" :title="'Minimum completions per task category'" :helptext="'Players must complete at least this minimum number of tasks per task category.'" :type="'integer'" :value="shownStage.minCompletionsPerTaskCategory" v-on:update="$set(shownStage, 'minCompletionsPerTaskCategory', $event)" :errorMessage="getErrorMessage('min_completions_per_task_category', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        <FormInput v-show="shownStage.showChapterMusicUrl" :title="'Chapter music URL'" :helptext="'Play music that will loop infinitely. Whether it auto-plays depends on player\'s device. Music can be stopped anytime by player.'" :type="'mp3-url'" :value="shownStage.chapterMusicUrl" v-on:update="shownStage.chapterMusicUrl = $event" :errorMessage="getErrorMessage('chapter_music_url', { fieldName: 'stages', stageIndex: shownStageIndex })"/>
        <FormInput v-show="shownStage.showHasVirtualSpaces" :title="'Has virtual space'" :helptext="'Turn panoramic images into virtual spaces where players can move, explore, and discover items.'" :type="'boolean'" :value="shownStage.hasVirtualSpaces" v-on:update="shownStage.hasVirtualSpaces = $event"/>
        <div class="mb-3" v-show="shownStage.hasVirtualSpaces">
          <label class="label mb-0">Scenes</label>
          <p class="is-size-7 mt-0 mb-1 is-clickable has-text-info" @click="openPage('https://medium.com/@playtours/guide-to-creating-your-very-own-virtual-space-in-playtour-8f8c985b47b3')">
            <i class="fas fa-external-link-alt mr-1"></i>
            Read: Guide To Creating Your Very Own Virtual Space in PlayTours
          </p>
          <p class="help has-text-grey-light mt-0 mb-1">
            BETA: Scenes that players can move to and from, or items that players can discover. This editor will be undergoing improvements to make it more intuitive in the future.
          </p>
          <button
            @click="toggleVirtualSpace(shownStageIndex)"
            class="button has-text-weight-semibold is-rounded is-small mt-0 mb-2"
          >
            <i class="fas fa-vr-cardboard is-clickable mr-2"></i>
              Preview Virtual Space
          </button>
          <div class="modal" :class="{'is-active': showVirtualSpace}">
            <div class="modal-background is-clickable" @click="toggleVirtualSpace"></div>
            <div class="modal-card" style="width: 90%; height: 90%;">
              <header class="modal-card-head">
                <p class="modal-card-title">Virtual Spaces Preview</p>
                <button class="delete is-clickable" aria-label="close" @click="toggleVirtualSpace"></button>
              </header>
              <section class="modal-card-body">
                <v-pannellum v-if="panellumData" :src="panellumData" style="height: 100%;"></v-pannellum>
              </section>
            </div>
            <button class="modal-close is-large is-clickable" aria-label="close" @click="toggleVirtualSpace"></button>
          </div>
          <div class="main-box mb-3" v-for="(virtualSpace, index1) in shownStage.virtualSpaces" :key="index1">
            <nav class="level is-mobile mb-0">
              <div class="level-left">
                <div class="level-item">
                  <label class="label">Scene {{ index1 + 1 }} {{ virtualSpace.name ? "- " + virtualSpace.name : '' }}</label>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div
                    v-if="shownStage.virtualSpaces.length > 1"
                    @click="deleteScene(shownStageIndex, index1)"
                    class="button is-grey is-outlined is-small is-rounded mb-2 ml-2"
                  >
                    Delete scene
                    <i class="fas fa-trash is-clickable ml-2"></i>
                  </div>
                </div>
              </div>
            </nav>
            <FormInput :title="'Name'" :mandatory="true" :type="'text'" :value="virtualSpace.name" v-on:update="virtualSpace.name = $event" :errorMessage="getErrorMessage('name', { fieldName: 'virtual_spaces', stageIndex: shownStageIndex, sceneIndex: index1 })"/>
            <FormInput :title="'Image URL'" :mandatory="true" :helperImage="'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F20.png?alt=media&token=9ba3eb5e-a80e-4c3c-a68f-19ea862e0c4f'" :helptext="'Images must be equirectangular to be displayed correctly'" :type="'image-url'" :value="virtualSpace.imageUrl" v-on:update="virtualSpace.imageUrl = $event" :errorMessage="getErrorMessage('imageUrl', { fieldName: 'virtual_spaces', stageIndex: shownStageIndex, sceneIndex: index1 })"/>
            <label class="label">Adjacent Scenes / Items</label>
            <div class="main-box mb-3" v-for="(adjacentScene, index2) in virtualSpace.adjacentScenes" :key="index2">
              <nav class="level is-mobile mb-0">
                <div class="level-left">
                  <div class="level-item">
                    <label v-if="adjacentScene.taskNumber" class="label">Task</label>
                    <label v-else-if="adjacentScene.imageUrl" class="label">Item</label>
                    <label v-else class="label">Adjacent Scene</label>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <div
                      @click="deleteAdjacentScene(shownStageIndex, index1, index2)"
                      class="button is-grey is-outlined is-small is-rounded mb-2 ml-2"
                    >
                      Delete adjacent scene / item
                      <i class="fas fa-trash is-clickable ml-2"></i>
                    </div>
                  </div>
                </div>
              </nav>
              <FormInput :title="'Name'" :mandatory="true" :helptext="'Must be spelled the same as an existing scene OR used as an item name if Image URL is filled'" :type="'text'" :value="adjacentScene.name" v-on:update="adjacentScene.name = $event" :errorMessage="getErrorMessage('name', { fieldName: 'adjacent_scenes', stageIndex: shownStageIndex, sceneIndex: index1, adjacentSceneIndex: index2 })"/>
              <!-- <FormInput :title="'X-coordinate'" :mandatory="true" :type="'number'" :value="adjacentScene.x" v-on:update="adjacentScene.x = $event" :errorMessage="getErrorMessage('x', { fieldName: 'adjacent_scenes', stageIndex: shownStageIndex, sceneIndex: index1, adjacentSceneIndex: index2 })"/>
              <FormInput :title="'Y-coordinate'" :mandatory="true" :type="'number'" :value="adjacentScene.y" v-on:update="adjacentScene.y = $event" :errorMessage="getErrorMessage('y', { fieldName: 'adjacent_scenes', stageIndex: shownStageIndex, sceneIndex: index1, adjacentSceneIndex: index2 })"/> -->
              <PanellumXYInput
              :imageUrl="virtualSpace.imageUrl"
              :x="adjacentScene.x"
              :y="adjacentScene.y"
              v-on:updateXY="$set(virtualSpace.adjacentScenes, index2, {...adjacentScene, x: $event.x, y: $event.y})"
              />
              <FormInput :title="'Image URL'" :helptext="'If filled, when clicked, the image will open up, instead of moving to another scene'" :type="'image-url'" :value="adjacentScene.imageUrl" v-on:update="adjacentScene.imageUrl = $event" :errorMessage="getErrorMessage('imageUrl', { fieldName: 'adjacent_scenes', stageIndex: shownStageIndex, sceneIndex: index1, adjacentSceneIndex: index2 })"/>
              <FormInput :title="'Task Number'" :helptext="'If filled, when clicked, the task at that number position will open'" :type="'integer'" :value="adjacentScene.taskNumber" v-on:update="adjacentScene.taskNumber = $event" :errorMessage="getErrorMessage('taskNumber', { fieldName: 'adjacent_scenes', stageIndex: shownStageIndex, sceneIndex: index1, adjacentSceneIndex: index2 })"/>
            </div>
            <div class="container has-text-centered mt-5">
              <button
                @click="addAdjacentScene(shownStageIndex, index1)"
                class="button has-text-weight-semibold is-rounded is-small mt-0"
              >
                <i class="fas fa-plus is-clickable mr-2"></i>
                  Add adjacent scene / item
              </button>
            </div>
          </div>
          <div class="container has-text-centered mt-5">
            <button
              @click="addScene(shownStageIndex)"
              class="button has-text-weight-semibold is-rounded is-small mt-0"
            >
              <i class="fas fa-plus is-clickable mr-2"></i>
                Add scene
            </button>
          </div>
          <button
            @click="toggleVirtualSpace(shownStageIndex)"
            class="button has-text-weight-semibold is-rounded is-small mt-0 mb-2"
          >
            <i class="fas fa-vr-cardboard is-clickable mr-2"></i>
              Preview Virtual Space
          </button>
        </div>
        <label class="label ml-3 mb-2"><i class="fas fa-cogs mt-3 mr-2"></i>Additional Configurations</label>
        <div class="buttons">
          <button v-if="!shownStage.showBriefing" class="button is-primary is-outlined is-rounded is-small" @click="$set(shownStage, 'showBriefing', true)"><i class="fas fa-music mr-2"></i>Add chapter text (shows above list of tasks)</button>
          <button v-if="!shownStage.showMinPoints || (shownStage.minPoints && shownStage.minPoints == calculateTotalTaskPoints(shownStageIndex))" class="button is-primary is-outlined is-rounded is-small" @click="$set(shownStage, 'showMinPoints', true); shownStage.minPoints = 1"><i class="fas fa-forward mr-2"></i>Teams need not complete all tasks</button>
          <button v-if="!shownStage.showTimeLimitInMinutes && !shownStage.timeLimitInMinutes" class="button is-primary is-outlined is-rounded is-small" @click="$set(shownStage, 'showTimeLimitInMinutes', true)"><i class="fas fa-stopwatch mr-2"></i>Add time limit</button>
          <button v-if="!shownStage.showChapterBackgroundUrl" class="button is-primary is-outlined is-rounded is-small" @click="$set(shownStage, 'showChapterBackgroundUrl', true)"><i class="fas fa-image mr-2"></i>Add custom background</button>
          <button v-if="!shownStage.showChapterMusicUrl" class="button is-primary is-outlined is-rounded is-small" @click="$set(shownStage, 'showChapterMusicUrl', true)"><i class="fas fa-music mr-2"></i>Add background music</button>
          <button v-if="!shownStage.showHasVirtualSpaces" class="button is-primary is-outlined is-rounded is-small" @click="$set(shownStage, 'showHasVirtualSpaces', true); shownStage.hasVirtualSpaces = true"><i class="fas fa-walking mr-2"></i>Add virtual space</button>
          <button
            @click="copyTasksToAnotherChapter(shownStageIndex)"
            class="button is-primary is-outlined is-rounded is-small"
          >
            <i class="fas fa-copy is-clickable mr-2"></i>
            Copy all tasks to another chapter
          </button>
        </div>
      </div>
    </article>

    <article v-if="showMainDetailsBox" class="mb-3" style="background-color: rgb(252 252 252);">
      <div class="is-flex is-justify-content-space-between">
        <button @click="triggerBackButton" class="button is-primary has-text-weight-bold mb-2">
          <i class="fas fa-chevron-left fa-xs mr-2"></i>
          Back
        </button>
        <div v-if="!adventureId && !error" class="has-text-grey mr-3">
          You can test the game & continue editing after clicking "Create game"
        </div>
        <div class="level-item my-0 py-0">
          <div
          @click="createAdventure(null)"
          class="button is-primary"
          :disabled="adventureId && savedPlayTours"
          v-bind:class="{'is-loading': loading}">
            <i class="fas fa-save mr-2"></i>
            {{ adventureId ? 'Save changes' : 'Create game & start testing' }}
          </div>
        </div>
      </div>
      <div class="stage-header">
        <nav class="level is-mobile mb-0">
          <div class="level-left">
            <div class="level-item">
              <div @click="toggleMainDetailsBox()" class="unselectable is-clickable has-text-weight-bold is-size-4 mb-1" v-bind:class="{'has-text-danger': errorSections.includes('details')}">
                Game Details & Welcome Screen
                <i v-if="!showMainDetailsBox" class="fas fa-edit fa-xs ml-2"></i>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            </div>
          </div>
        </nav>
      </div>

      <div class="message-body">
        <FormInput :title="'Game title'" :helperImage="screenshots[1]" :mandatory="true" :min="6" :max="100" :type="'text'" :value="name" v-on:update="name = $event" :errorMessage="getErrorMessage('name', null)"/>
        <FormInput :title="'Cover photo'" :mandatory="true" :helperImage="screenshots[1]" :helptext="'Shown to players before starting the game.'" :type="'image-url'" :value="previewImageUrl" v-on:update="previewImageUrl = $event" :errorMessage="getErrorMessage('preview_image_url', null)"/>
        <FormInput :title="'Game text'" :helperImage="screenshots[2]" :mandatory="true" :helptext="'Shown when players select this game, before creating a team and in the waiting lobby'" :type="'markdown'" :value="briefing" v-on:update="briefing = $event" :errorMessage="getErrorMessage('briefing', null)"/>
        <FormInput :title="'Time limit'" :unit="'minute(s)'" :helperImage="screenshots[18]" :helptext="'In minutes. Sets a global timer to the game, once the time ends, teams can still play for points but it will not be added to the leaderboard, unless \'End game upon time limit\' is set to Yes.'" :videoTutorialYoutubeId="'iX5vN3ANZ_g'" :type="'number'" :value="timeLimitInMinutes" v-on:update="timeLimitInMinutes = $event" :errorMessage="getErrorMessage('time_limit_in_minutes', null)"/>
        <FormInput class="ml-5" v-if="timeLimitInMinutes" :title="'End game upon time limit'" :helptext="'End the game and show the debrief once time limit is reached.'" :type="'boolean'" :value="endAfterTimeLimit" v-on:update="endAfterTimeLimit = $event" :errorMessage="getErrorMessage('end_after_time_limit', null)"/>
        <FormInput :title="'Starting coins'" :unit="'coin(s)'" :helperImage="screenshots[4]" :helptext="'Coins are used to buy clues and is given at the start of the game; teams cannot earn any coins during the game'" :videoTutorialYoutubeId="'CA6_90aZPUE'" :type="'number'" :value="startingCredits" v-on:update="startingCredits = $event" :errorMessage="getErrorMessage('starting_credits', null)"/>
        <!-- <FormInput :title="'Shuffle chapters'" :helptext="'All chapters will be randomly shuffled for every team. Useful for games that require teams to split up to prevent bottlenecks.'" :type="'boolean'" :value="shuffleChapters" v-on:update="shuffleChapters = $event" :errorMessage="getErrorMessage('shuffle_chapters', null)"/> -->
        <!-- <FormInput v-if="shuffleChapters" :title="'Do not shuffle first and last chapters'" :helptext="'Prevents the first and final chapters from being shuffled. These chapters are usually used for orientation and bring teams back to a gathering point.'" :type="'boolean'" :value="shuffleChaptersNotFirstLast" v-on:update="shuffleChaptersNotFirstLast = $event" :errorMessage="getErrorMessage('shuffle_chapter_not_first_last', null)"/> -->
        <FormInput :title="'Auto translate'" :helptext="'Allow players to select another language. Translations automatically done by Google Translate. If enabled, do inform players in the game description on the supported languages, and ensure that answers take into account the different languages.'" :type="'boolean'" :value="allowTranslation" v-on:update="allowTranslation = $event" :errorMessage="getErrorMessage('allow_translation', null)"/>
        <FormInput class="ml-5" v-if="allowTranslation" :title="'Use specific language'" :helptext="'Default translation to a specific language.'" :type="'dropdown'" :selections="defaultLanguageSelectionssss" :value="defaultLanguage" v-on:update="defaultLanguage = $event" :errorMessage="getErrorMessage('default_language', null)"/>
      </div>
    </article>

    <article v-if="showEndingBox" class="message mb-3" style="background-color: rgb(252 252 252);">
      <div class="is-flex is-justify-content-space-between">
        <button @click="triggerBackButton" class="button is-primary has-text-weight-bold mb-2">
          <i class="fas fa-chevron-left fa-xs mr-2"></i>
          Back
        </button>
        <div v-if="!adventureId && !error" class="has-text-grey mr-3">
          You can test the game & continue editing after clicking "Create game"
        </div>
        <div class="level-item my-0 py-0">
          <div
          @click="createAdventure(null)"
          class="button is-primary"
          :disabled="adventureId && savedPlayTours"
          v-bind:class="{'is-loading': loading}">
            <i class="fas fa-save mr-2"></i>
            {{ adventureId ? 'Save changes' : 'Create game & start testing' }}
          </div>
        </div>
      </div>
      <div class="stage-header">
        <nav class="level is-mobile mb-0">
          <div class="level-left">
            <div class="level-item">
              <div @click="toggleEndingBox()" class="unselectable is-clickable has-text-weight-bold is-size-4 mb-1" v-bind:class="{'has-text-danger': errorSections.includes('ending')}">
                Ending
                <i v-if="!showEndingBox" class="fas fa-edit fa-xs ml-2"></i>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            </div>
          </div>
        </nav>
      </div>

      <div class="message-body">
        <FormInput :title="'Debrief'" :helperImage="screenshots[19]" :helptext="'Shown when teams complete the entire game'" :type="'markdown'" :value="debrief" v-on:update="debrief = $event" :errorMessage="getErrorMessage('debrief', null)"/>
        <FormInput :title="'Redirect URL'" :helptext="'URL to send players to when they click Leave Game after completing the game. Must include \'http://\' or \'https://\'.'" :type="'text'" :value="redirectUrl" v-on:update="redirectUrl = $event" :errorMessage="getErrorMessage('redirect_url', null)"/>
      </div>
    </article>

    <div v-if="!shownStage && !showMainDetailsBox && !showEndingBox">

      <div class="level mb-2">
        <div class="level-left">
          <div class="level-item">
            <div v-if="!savedPlayTours && unsavedChanges > 0" class="has-text-grey-light">
              <i class="fas fa-exclamation-circle"></i>
              Unsaved changes
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item mr-0">
            <b-switch v-model="shuffleChapters">
            </b-switch>
          </div>
          <div class="level-item has-text-weight-semibold mr-3 mb-0">
            Shuffle chapters & prevent crowding
          </div>
          <div v-if="shuffleChapters" class="level-item mr-0">
            <b-switch v-model="shuffleChaptersNotFirstLast">
            </b-switch>
          </div>
          <div v-if="shuffleChapters" class="level-item has-text-weight-semibold mr-3 mb-0">
            Do not shuffle 1st/last chapters
          </div>
          <div v-if="shuffleChapters" class="level-item mr-0">
            <b-switch v-model="shuffleChaptersPreserveSequence">
            </b-switch>
          </div>
          <div v-if="shuffleChapters" class="level-item has-text-weight-semibold mr-3 mb-0">
            Preserve sequence
          </div>
          <!-- <div v-if="shuffleChapters" class="level-item mr-0">
            <b-field label="" horizontal>
              <b-switch v-model="preventBunching">
              </b-switch>
            </b-field>
          </div>
          <div v-if="shuffleChapters" class="level-item has-text-weight-semibold mr-0 mb-0">
            Prevent bunching
          </div> -->
        </div>
      </div>

      <div class="stage-header">
        <nav class="level is-mobile mb-0">
          <div class="level-left">
            <div class="level-item">
              <div @click="toggleMainDetailsBox()" class="unselectable is-clickable has-text-weight-bold is-size-4 mb-1" v-bind:class="{'has-text-danger': errorSections.includes('details')}">
                Game Details & Welcome Screen
                <i v-if="showMainDetailsBox" class="fas fa-times-circle fa-xs ml-2"></i>
                <i v-if="!showMainDetailsBox" class="fas fa-edit fa-xs ml-2"></i>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            </div>
          </div>
        </nav>
      </div>

      <div v-if="!shuffleChapters || (shuffleChapters && shuffleChaptersNotFirstLast)" class="stage-connector"></div>
      <div v-else class="mt-2"></div>

      <draggable v-model="stages">
        <div v-for="(entry, index) in stages" :key="index">
          <article class="message mb-0" style="background-color: rgb(252 252 252);">
            <div class="stage-header">
              <nav class="level is-mobile mb-0">
                <div @click="toggleShowStage(index)" class="level-left is-clickable">
                  <div class="level-item">
                    <div class="unselectable has-text-weight-bold is-size-4 mb-1" :class="{'has-text-danger': errorSections.includes(index)}">
                      <div class="is-size-7 has-text-weight-normal mb-1">
                        <i v-if="shuffleChapters && !(shuffleChapters && shuffleChaptersNotFirstLast && (index === stages.length - 1 || index === 0))" class="fas fa-random fa-xs has-text-grey-light mr-1" style="transform: rotate(90deg); padding-right: 1px;"></i>
                        <span v-if="shuffleChapters && !(shuffleChapters && shuffleChaptersNotFirstLast && (index === stages.length - 1 || index === 0))" class="has-text-grey-light">Sequence shuffled</span>
                      </div>
                      <div>
                        <div class="is-flex is-align-items-center" :class="{'is-size-7 has-text-grey': entry.title, 'is-size-5': !entry.title}">
                          Chapter {{ index + 1 }}
                          <i v-if="!entry.title && entry.show" class="fas fa-times-circle fa-xs ml-2"></i>
                          <i v-if="!entry.title && !entry.show" class="fas fa-edit fa-xs ml-2"></i>
                        </div>
                        <div class="is-flex is-align-items-center" v-if="entry.title">
                          <span>{{ entry.title }}</span>
                          <i v-if="entry.show" class="fas fa-times-circle fa-xs ml-2"></i>
                          <i v-if="!entry.show" class="fas fa-edit fa-xs ml-2"></i>
                        </div>
                      </div>
                      <!-- <div>
                        Chapter {{ index + 1 }}{{ !entry.title && entry.briefing && entry.briefing.length > 0 ? `: ${entry.briefing.substring(0, 20)}...` : entry.title ? `: ${entry.title}` : null }}
                      </div> -->
                      <!-- <i v-if="entry.show" class="fas fa-times-circle fa-xs ml-2"></i>
                      <i v-if="!entry.show" class="fas fa-edit fa-xs ml-2"></i> -->
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <i v-if="index > 0" @click="changeStageSequence(index, -1)" class="fas fa-arrow-up is-clickable ml-2"></i>
                    <i v-if="index < stages.length-1" @click="changeStageSequence(index, 1)" class="fas fa-arrow-down is-clickable ml-2"></i>
                    <i @click="duplicateStage(index)" class="fas fa-copy is-clickable ml-2"></i>
                    <i v-if="stages.length > 1" @click="deleteStage(index)" class="fas fa-trash-alt is-clickable has-text-danger ml-2"></i>
                  </div>
                </div>
              </nav>
              <div @click="toggleShowStage(index)">
                <span
                class="tag is-rounded is-clickable"
                style="background-color: transparent; border-color: #3e2d6d; color: #3e2d6d; border: 1px solid;">
                {{ `${entry.challenges.length} task${entry.challenges.length !== 1 ? 's' : ''}, ${entry.minPoints ? entry.minPoints : calculateTotalTaskPoints(index)} point${(entry.minPoints ? entry.minPoints : calculateTotalTaskPoints(index)) !== 1 ? 's' : ''} required to complete` }}
                </span>
              </div>
            </div>
          </article>
          <div v-if="!shuffleChapters" class="stage-connector"></div>
          <div v-else-if="stages.length-1 == index && shuffleChaptersNotFirstLast" class="stage-connector"></div>
          <div v-else class="mt-2"></div>
        </div>
      </draggable>

      <!-- <div v-if="!shuffleChapters || (shuffleChapters && shuffleChaptersNotFirstLast)" class="stage-connector"></div> -->

      <div class="is-flex is-align-items-center">
        <button @click="addStage()" class="button is-primary is-outlined is-small is-rounded is-size-6 has-text-weight-bold">
          <i class="fas fa-plus-circle mr-2"></i>
          Add Chapter {{ stages.length + 1 }}
        </button>
        <ChapterTutorialModal v-if="stages.length === 1" class="ml-3" />
      </div>

      <div v-if="!shuffleChapters || (shuffleChapters && shuffleChaptersNotFirstLast)" class="stage-connector"></div>
      <div v-else class="mt-2"></div>

      <div class="stage-header">
        <nav class="level is-mobile mb-0">
          <div class="level-left">
            <div class="level-item">
              <div @click="toggleEndingBox()" class="unselectable is-clickable has-text-weight-bold is-size-4 mb-1" v-bind:class="{'has-text-danger': errorSections.includes('ending')}">
                Ending
                <i v-if="showEndingBox" class="fas fa-times-circle fa-xs ml-2"></i>
                <i v-if="!showEndingBox" class="fas fa-edit fa-xs ml-2"></i>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            </div>
          </div>
        </nav>
      </div>

      <hr>

      <article class="message is-medium mt-4" style="background-color: rgb(252 252 252);">
        <!-- <div class="message-header" style="background-color: #e2e2e2; color: #4a4a4a;">
          <p>Miscellaneous Configurations</p>
        </div> -->
        <div class="message-body has-text-dark py-2">
          <nav class="level my-0">
            <div class="level-left">
              <div @click="toggleItemsBox()" class="unselectable is-clickable has-text-weight-bold is-size-5 mb-1" v-bind:class="{'has-text-danger': errorSections.includes('items')}">
                Items
                <i v-if="showItemsBox" class="fas fa-chevron-circle-down fa-xs ml-2"></i>
                <i v-if="!showItemsBox" class="fas fa-chevron-circle-right fa-xs ml-2"></i>
              </div>
            </div>
          </nav>
          <div v-if="showItemsBox" class="main-box mb-4">
            <article class="message">
              <div class="message-body">
                Items can be collected by players by checking-in at a location, scanning a QR or barcode, or when it is triggered by reaching a chapter, time or point limit.
              </div>
            </article>
            <div class="pl-3">
              <div class="main-box task-box mb-3" v-for="(entry, index) in searchableItems" :key="index" :class="{'has-background-danger-dark has-text-white': errorTasks[index] && errorTasks[index].includes(index1)}">
                <div class="level is-mobile mb-0">
                  <div @click="expandItem(index)" class="is-clickable level-left">
                    <div class="level-item pl-4">
                      <i class="fas fa-edit mr-2"></i>
                      <p class="is-size-6 has-text-weight-semibold">{{ entry.item ? entry.item.substring(0, 15) + ' ...' : '[item text not set]' }}</p>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <div
                        @click="deleteFromArray('searchableItems', index, 0)"
                        class="button is-grey is-outlined is-small is-rounded ml-2"
                      >
                        Delete
                        <i class="fas fa-trash is-clickable ml-2"></i>
                      </div>
                      <div @click="expandItem(index)" class="button is-outlined is-small is-rounded ml-2">
                        Edit
                        <i class="fas fa-edit fa-xs ml-2"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal" :class="{ 'is-active': shownItem !== null && shownItem === index }">
                  <div @click="expandItem(null)" class="modal-background is-clickable"></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <!-- <p class="modal-card-title has-text-weight-semibold">{{ challenge.name }}{{challenge.points ? ` (${challenge.points} points)` : null}}</p> -->
                      <button @click="expandItem(null)" class="delete" aria-label="close"></button>
                    </header>
                    <section class="modal-card-body pt-0">
                      <FormInput :title="'Item text'" :helperImage="screenshots[5]" :type="'markdown'" :mandatory="true" :value="entry.item" v-on:update="$set(searchableItems, index, {...entry, item: $event })" :errorMessage="getErrorMessage('item', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Virtual space'" :helptext="'Shows a single virtual space where players can look around in 360 degree view. Images must be equirectangular to be displayed correctly.'" :type="'image-url'" :value="entry.virtual_space_image_url" v-on:update="$set(searchableItems, index, {...entry, virtual_space_image_url: $event })" :errorMessage="getErrorMessage('virtual_space_image_url', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Scan code'" :helperImage="screenshots[6]" :helptext="'Allow items to be discovered by scanning a QR or barcode.'" :type="'text'" :value="entry.scanCode" :showQr="true" v-on:update="$set(searchableItems, index, {...entry, scanCode: $event })" :errorMessage="getErrorMessage('scanCode', { fieldName: 'searchableItems', index })"/>
                      <LatLonInput
                      :helptext="'Allow item to be discovered by location.'"
                      :lat="entry.lat" :lon="entry.lon"
                      :radius="entry.radius_in_meters"
                      v-on:updateLatLonRad="$set(searchableItems, index, {...entry, lat: $event.lat, lon: $event.lon, radius_in_meters: $event.radius })"
                      v-on:updateRadius="$set(searchableItems, index, {...entry, radius_in_meters: $event })"
                      v-on:updateLat="$set(searchableItems, index, {...entry, lat: $event })"
                      v-on:updateLon="$set(searchableItems, index, {...entry, lon: $event })"
                      />
                      <FormInput :title="'Shown on map'" :helptext="'Allow players to see a ? icon on the map.'" :type="'boolean'" :value="entry.shown_on_map" v-on:update="$set(searchableItems, index, {...entry, shown_on_map: $event })" :errorMessage="getErrorMessage('shown_on_map', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Chapter'" :helptext="'Leave blank if not applicable. Will trigger when chapter is reached. If minutes is inputted below, then it will follow the minutes logic instead.'" :type="'integer'" :value="entry.stage" v-on:update="$set(searchableItems, index, {...entry, stage: $event })" :errorMessage="getErrorMessage('stage', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Minutes from game/chapter'" :helptext="'Leave blank if not applicable. Will trigger from start of game time. If chapter is inputted, then it will trigger from start of chapter instead.'" :type="'integer'" :value="entry.minutes" v-on:update="$set(searchableItems, index, {...entry, minutes: $event })" :errorMessage="getErrorMessage('minutes', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Points collected'" :helptext="'Leave blank if not applicable. Will trigger when team reaches total points. If chapter is inputted, then it will only account for points collected in that chapter.'" :type="'integer'" :value="entry.points" v-on:update="$set(searchableItems, index, {...entry, points: $event })" :errorMessage="getErrorMessage('points', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Consider Tasks only from this task category'" :helptext="'Useful for items that depend on specific tasks only.'" :type="'text'" :value="entry.taskCategory" v-on:update="$set(searchableItems, index, {...entry, taskCategory: $event })" :errorMessage="getErrorMessage('taskCategory', { fieldName: 'searchableItems', index })"/>
                      <FormInput :title="'Category'" :helptext="'Players can filter items in the inventory by category.'" :type="'text'" :value="entry.category" v-on:update="$set(searchableItems, index, {...entry, category: $event })" :errorMessage="getErrorMessage('category', { fieldName: 'searchableItems', index })"/>
                      <FormInput v-if="uiMods.useItemOrder" :title="'Order'" helptext="Sort items' order in the inventory. For example, item order 3 will always appear above item order 5. Useful if your items need to be logically ordered. Items without configured orders will always appear above items with orders." :type="'number'" :value="entry.order" v-on:update="$set(searchableItems, index, {...entry, category: $event })" :errorMessage="getErrorMessage('order', { fieldName: 'searchableItems', index })"/>
                    </section>
                    <footer class="modal-card-foot">
                      <button @click="expandItem(null)" class="button is-primary">Done</button>
                    </footer>
                  </div>
                </div>
              </div>
              <div class="container has-text-centered mt-5">
                <button
                  @click="addToSearchableItemsArray()"
                  class="button has-text-weight-semibold is-primary is-rounded is-small mt-0"
                >
                  <i class="fas fa-plus is-clickable mr-2"></i>
                    Add Item
                </button>
              </div>
            </div>
          </div>

          <nav class="level my-0">
            <div class="level-left">
              <div @click="showUiModsBox = !showUiModsBox" class="unselectable is-clickable has-text-weight-bold is-size-5 mb-1">
                UI Modifications
                <i v-if="showUiModsBox" class="fas fa-chevron-circle-down fa-xs ml-2"></i>
                <i v-if="!showUiModsBox" class="fas fa-chevron-circle-right fa-xs ml-2"></i>
              </div>
            </div>
          </nav>
          <div v-if="showUiModsBox" class="main-box mb-4">
            <article class="message">
              <div class="message-body">
                UI modifications is an advanced customizability feature in PlayTours to change the wording and look & feel of the app. Drop us a message via Live Chat or hello@playtours.app to learn more.
              </div>
            </article>
            <div class="tags is-flex is-align-items-center">
              <div
              v-for="category in ['Look & Feel', 'Text Replacements', 'Progress Bar, Points & Leaderboard', 'Navigation & Behaviour']" :key="category"
              class="tag is-primary is-medium is-rounded is-clickable px-4 m-0 mr-2 mb-1"
              :class="{ 'is-light': uiModsCategoryFilter !== category, '': uiModsCategoryFilter === category } "
              @click="uiModsCategoryFilter && uiModsCategoryFilter === category ? uiModsCategoryFilter = null : uiModsCategoryFilter = category"
              >
                {{ category }}
              </div>
            </div>
            <div class="pl-3">
              <div v-show="!uiModsCategoryFilter || uiModsCategoryFilter == 'Look & Feel'">
                <FormInput :title="'Welcome Screen Title'" :mandatory="false" :helptext="'Shown at the top of the welcome screen. By default, it shows your organisation\'s title if you have configured it under the Organisation settings. The welcome screen only appears if you are using the Game Code, not the Team Code.'" :type="'text'" :value="uiMods.welcomeScreenTitle" v-on:update="$set(uiMods, 'welcomeScreenTitle', $event)" :errorMessage="getErrorMessage('welcomeScreenTitle', null)"/>
                <FormInput :title="'Welcome Screen Logo'" :mandatory="false" :helptext="'Shown at the top of the welcome screen. By default, it shows your organisation\'s logo if you have configured it under the Organisation settings. The welcome screen only appears if you are using the Game Code, not the Team Code.'" :type="'image-url'" :value="uiMods.welcomeScreenLogo" v-on:update="$set(uiMods, 'welcomeScreenLogo', $event)" :errorMessage="getErrorMessage('welcomeScreenLogo', null)"/>
                <FormInput :title="'Default Background Colour'" :type="'color'" :value="uiMods.defaultBackgroundColour" v-on:update="$set(uiMods, 'defaultBackgroundColour', $event)" />
                <FormInput :title="'Font URL'" helptext="Drop us an email at hello@playtours.app and send us your font file to get a Font URL." :type="'text'" :value="uiMods.customFontUrl" v-on:update="$set(uiMods, 'customFontUrl', $event)" />
                <FormInput :title="'Intro Button Background Colour'" :type="'color'" :value="uiMods.customButtonBackgroundColour" v-on:update="$set(uiMods, 'customButtonBackgroundColour', $event)" />
                <FormInput :title="'Intro Button Font Colour'" :type="'color'" :value="uiMods.customButtonFontColour" v-on:update="$set(uiMods, 'customButtonFontColour', $event)" />
                <FormInput :title="'Primary Button Background Colour'" :type="'color'" :value="uiMods.customPrimaryButtonBackgroundColour" v-on:update="$set(uiMods, 'customPrimaryButtonBackgroundColour', $event)" />
                <FormInput :title="'Primary Button Font Colour'" :type="'color'" :value="uiMods.customPrimaryButtonFontColour" v-on:update="$set(uiMods, 'customPrimaryButtonFontColour', $event)" />
                <FormInput :title="'Main Section Background Colour'" :type="'color'" :value="uiMods.customMainSectionBackgroundColour" v-on:update="$set(uiMods, 'customMainSectionBackgroundColour', $event)" />
                <FormInput :title="'Main Section Font Colour'" :type="'color'" :value="uiMods.customMainSectionFontColour" v-on:update="$set(uiMods, 'customMainSectionFontColour', $event)" />
                <FormInput :title="'Task List Pending Task Indicator Colour'" :type="'color'" :value="uiMods.pendingTaskIndicatorColour" v-on:update="$set(uiMods, 'pendingTaskIndicatorColour', $event)" />
                <FormInput :title="'Task List Completed Task Indicator Colour'" :type="'color'" :value="uiMods.completedTaskIndicatorColour" v-on:update="$set(uiMods, 'completedTaskIndicatorColour', $event)" />
                <FormInput :title="'Leaderboard Exhibit Rank Background Colour'" :type="'color'" :value="uiMods.customLeaderboardExhibitRankBackgroundColour" v-on:update="$set(uiMods, 'customLeaderboardExhibitRankBackgroundColour', $event)" />
                <FormInput :title="'Leaderboard Exhibit Rank Text Colour'" :type="'color'" :value="uiMods.customLeaderboardExhibitRankTextColour" v-on:update="$set(uiMods, 'customLeaderboardExhibitRankTextColour', $event)" />
                <FormInput :title="'Hide Timer Notices'" :type="'boolean'" :value="uiMods.hideTimerNotices" v-on:update="$set(uiMods, 'hideTimerNotices', $event)" />
              </div>
              <div v-show="!uiModsCategoryFilter || uiModsCategoryFilter == 'Text Replacements'">
                <FormInput :title="'Your details'" helptext="Replace with..." :type="'text'" :value="uiMods.yourDetailsText" v-on:update="$set(uiMods, 'yourDetailsText', $event)" />
                <FormInput :title="'My name is...'" helptext="Replace with..." :type="'text'" :value="uiMods.playerNameText" v-on:update="$set(uiMods, 'playerNameText', $event)" />
                <FormInput :title="'Team Name'" helptext="Replace with..." :type="'text'" :value="uiMods.teamNameText" v-on:update="$set(uiMods, 'teamNameText', $event)" />
                <FormInput :title="'Game'" :type="'text'" helptext="Replace with..." :value="uiMods.game" v-on:update="$set(uiMods, 'game', $event)" />
                <FormInput :title="'Chapter'" :type="'text'" helptext="Replace with..." :value="uiMods.chapter" v-on:update="$set(uiMods, 'chapter', $event)" />
                <FormInput :title="'Task'" :type="'text'" helptext="Replace with..." :value="uiMods.task" v-on:update="$set(uiMods, 'task', $event)" />
                <FormInput :title="'Point'" :type="'text'" helptext="Replace with..." :value="uiMods.point" v-on:update="$set(uiMods, 'point', $event)" />
                <FormInput :title="'Clue'" :type="'text'" helptext="Replace with..." :value="uiMods.clue" v-on:update="$set(uiMods, 'clue', $event)" />
                <FormInput :title="'Buy clue'" :type="'text'" helptext="Replace with..." :value="uiMods.buyClue" v-on:update="$set(uiMods, 'buyClue', $event)" />
                <FormInput :title="'Show clue'" :type="'text'" helptext="Replace with..." :value="uiMods.showClue" v-on:update="$set(uiMods, 'showClue', $event)" />
                <FormInput :title="'Coin'" :type="'text'" helptext="Replace with..." :value="uiMods.coin" v-on:update="$set(uiMods, 'coin', $event)" />
                <FormInput v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')"
                :title="'Powerup'" :type="'text'" helptext="Replace with..." :value="uiMods.powerup" v-on:update="$set(uiMods, 'powerup', $event)" />
                <FormInput v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')"
                :title="'Powerup Subtext'" :type="'text'" helptext="Replace with..." :value="uiMods.powerupSubtext" v-on:update="$set(uiMods, 'powerupSubtext', $event)" />
                <FormInput :title="'Chat'" :type="'text'" helptext="Replace with..." :value="uiMods.chat" v-on:update="$set(uiMods, 'chat', $event)" />
                <FormInput :title="'Chat Subtext'" :type="'text'" helptext="Subtext refers to the text below the heading when they are on the Chat page." :value="uiMods.chatSubtext" v-on:update="$set(uiMods, 'chatSubtext', $event)" />
                <FormInput :title="'Scan'" :type="'text'" helptext="Replace with..." :value="uiMods.scan" v-on:update="$set(uiMods, 'scan', $event)" />
                <FormInput :title="'Scan Subtext'" :type="'text'" helptext="Subtext refers to the text below the heading when they are on the Scan page." :value="uiMods.scanSubtext" v-on:update="$set(uiMods, 'scanSubtext', $event)" />
                <FormInput :title="'Inventory'" :type="'text'" helptext="Replace with..." :value="uiMods.inventory" v-on:update="$set(uiMods, 'inventory', $event)" />
                <FormInput :title="'Inventory Subtext'" :type="'text'" :value="uiMods.inventorySubtext" v-on:update="$set(uiMods, 'inventorySubtext', $event)" />
                <FormInput :title="'Gallery'" :type="'text'" helptext="Replace with..." :value="uiMods.gallery" v-on:update="$set(uiMods, 'gallery', $event)" />
                <FormInput :title="'Gallery Subtext'" :type="'text'" :value="uiMods.gallerySubtext" v-on:update="$set(uiMods, 'gallerySubtext', $event)" />
                <FormInput :title="'Loading...'" helptext="Replace with..." :type="'text'" :value="uiMods.loadingText" v-on:update="$set(uiMods, 'loadingText', $event)" />
                <FormInput :title="'Loading Image URL'" :type="'image-url'" :value="uiMods.loadingImageUrl" v-on:update="$set(uiMods, 'loadingImageUrl', $event)" />
                <FormInput :title="'Incorrect, try again'" helptext="Replace with..." :type="'text'" :value="uiMods.tryAgainText" v-on:update="$set(uiMods, 'tryAgainText', $event)" />
                <FormInput :title="'Task skipped'" helptext="Replace with..." :type="'text'" :value="uiMods.challengeSkippedText" v-on:update="$set(uiMods, 'challengeSkippedText', $event)" />
                <FormInput :title="'Wait for Faci Header'" helptext="By default, it is: 'Sent for game facilitator's assessment!' Replace with..." :type="'text'" :value="uiMods.waitForFaciHeader" v-on:update="$set(uiMods, 'waitForFaciHeader', $event)" />
                <FormInput :title="'Wait for Faci Message'" helptext="By default, it is: 'It might take some time. You can do other tasks while waiting.' Replace with..." :type="'text'" :value="uiMods.waitForFaciText" v-on:update="$set(uiMods, 'waitForFaciText', $event)" />
                <FormInput :title="'Facilitator'" :type="'text'" helptext="Replace with..." :value="uiMods.facilitator" v-on:update="$set(uiMods, 'facilitator', $event)" />
                <FormInput :title="'Location Access Permission'" :type="'boolean'" helptext="Default text: You must click ALLOW if asked for location access. Else, you will not be able to proceed." :value="uiMods.locationAccessPermission" v-on:update="$set(uiMods, 'locationAccessPermission', $event)" />
              </div>
              <div v-show="!uiModsCategoryFilter || uiModsCategoryFilter == 'Progress Bar, Points & Leaderboard'">
                <FormInput :title="'Use Game Wide Progress Bar'" :type="'boolean'" :value="uiMods.useGameWideProgressBar" v-on:update="$set(uiMods, 'useGameWideProgressBar', $event)" />
                <FormInput :title="'Progress Bar Colour'" :type="'dropdown'" :selections="[{key:'Default',value:null},{key:'White',value:'is-light'},{key:'Red',value:'is-danger'},{key:'Yellow',value:'is-warning'},{key:'Blue',value:'is-info'},{key:'Dark Blue',value:'is-link'},{key:'Green',value:'is-success'},]" :value="uiMods.progressBarColour" v-on:update="$set(uiMods, 'progressBarColour', $event)"/>
                <FormInput :title="'Hide Points Subtext'" :type="'boolean'" :value="uiMods.hidePointsSubtext" v-on:update="$set(uiMods, 'hidePointsSubtext', $event)" />
                <FormInput :title="'Hide Points Progress Bar'" :type="'boolean'" :value="uiMods.hidePointsProgressBar" v-on:update="$set(uiMods, 'hidePointsProgressBar', $event)" />
                <FormInput :title="'Hide Points Wording'" :type="'boolean'" :value="uiMods.hidePointsStarsWording" v-on:update="$set(uiMods, 'hidePointsStarsWording', $event)" />
                <FormInput :title="'Show Task Category Points'" :type="'boolean'" :value="uiMods.showTaskCategoryPoints" v-on:update="$set(uiMods, 'showTaskCategoryPoints', $event)" />
                <FormInput :title="'Hide Points In Leaderboard'" :type="'boolean'" :value="uiMods.hidePointsLeaderboard" v-on:update="$set(uiMods, 'hidePointsLeaderboard', $event)" />
                <FormInput :title="'Hide Points In Ending Screen'" :type="'boolean'" :value="uiMods.hidePointsEndingScreen" v-on:update="$set(uiMods, 'hidePointsEndingScreen', $event)" />
                <FormInput :title="'Hide Timings In Leaderboard'" :type="'boolean'" :value="uiMods.hideTimingsLeaderboard" v-on:update="$set(uiMods, 'hideTimingsLeaderboard', $event)" />
                <FormInput title="Always Show Teams' Names in Friendly Leaderboard" :type="'boolean'" :value="uiMods.alwaysShowTeamsNamesInFriendlyLeaderboard" v-on:update="$set(uiMods, 'alwaysShowTeamsNamesInFriendlyLeaderboard', $event)" />
                <FormInput :title="'Sort Teams By Name In Leaderboard'" :type="'boolean'" :value="uiMods.sortByNameLeaderboard" v-on:update="$set(uiMods, 'sortByNameLeaderboard', $event)" />
                <FormInput :title="'Hide Points System for Specific Chapters'" :type="'text'" helptext="Comma-separated: 1, 4, 6" :value="uiMods.hidePointsSystemSpecificChapters" v-on:update="$set(uiMods, 'hidePointsSystemSpecificChapters', $event)" />
                <FormInput :title="'Show Both Clan and Team points on the Leaderboard'" :type="'boolean'" helptext="Clan and teams points will both be shown on the leaderboard." :value="uiMods.showClansAndTeamsLeaderboard" v-on:update="$set(uiMods, 'showClansAndTeamsLeaderboard', $event)" />
                <FormInput :title="'Clans'" :type="'text'" helptext="Comma-separated: Hufflepuff, Gryffindor, Slytherin, Ravenclaw" :value="uiMods.clans" v-on:update="$set(uiMods, 'clans', $event)" />
                <FormInput :title="'Use Averaged Clan Points System'" :type="'boolean'" helptext="Instead of totalling points from every team in a clan, points are added up and divided by the total number of teams in a clan. This ensures that clans with less players are not disadvantaged. Only teams with more than 0 points are considered, to account only for active teams." :value="uiMods.clansAveragedPoints" v-on:update="$set(uiMods, 'clansAveragedPoints', $event)" />
              </div>
              <div v-show="!uiModsCategoryFilter || uiModsCategoryFilter == 'Navigation & Behaviour'">
                <FormInput :title="'Enable Custom Item Ordering'" :type="'boolean'" :value="uiMods.useItemOrder" v-on:update="$set(uiMods, 'useItemOrder', $event)" />
                <FormInput :title="'Sort items in the inventory from earliest collected to latest'" :type="'boolean'" helptext="By default, items that are collected first are at the bottom." :value="uiMods.itemsSortedFirstAtTop" v-on:update="$set(uiMods, 'itemsSortedFirstAtTop', $event)" />
                <FormInput :title="'Show Welcome Screen Info in Lobby'" :type="'boolean'" :value="uiMods.showAdventureIntroInLobby" v-on:update="$set(uiMods, 'showAdventureIntroInLobby', $event)" />
                <FormInput :title="'Location-based Tasks Map Height'" :type="'number'" helptext="Default is 250" :value="uiMods.tasksMapHeight" v-on:update="$set(uiMods, 'tasksMapHeight', $event)" />
                <FormInput :title="'Shuffle Multiple Choice Options'" :type="'boolean'" :value="uiMods.shuffleMultipleChoice" v-on:update="$set(uiMods, 'shuffleMultipleChoice', $event)" />
                <FormInput :title="'Make All Tasks Skippable'" :type="'boolean'" :value="uiMods.makeAllTasksSkippable" v-on:update="$set(uiMods, 'makeAllTasksSkippable', $event)" />
                <FormInput :title="'Enable Task Category Minimum Completions'" :type="'boolean'" :value="uiMods.enableTaskCategoryMinimumCompletions" v-on:update="$set(uiMods, 'enableTaskCategoryMinimumCompletions', $event)" />
                <FormInput :title="'Enable Story Mode'" :type="'boolean'" helptext="Allows teams to go to different chapters depending on their answers." :value="uiMods.enableStoryMode" v-on:update="$set(uiMods, 'enableStoryMode', $event)" />
                <FormInput :title="'Shuffle Task Categories'" :type="'boolean'" :value="uiMods.shuffleTaskCategories" v-on:update="$set(uiMods, 'shuffleTaskCategories', $event)" />
                <FormInput :title="'Auto-select First Task Category'" :type="'boolean'" :value="uiMods.autoSelectFirstTaskCategory" v-on:update="$set(uiMods, 'autoSelectFirstTaskCategory', $event)" />
                <FormInput :title="'Hide Show All Option for Task Categories'" :type="'boolean'" :value="uiMods.hideShowAllOptionForTaskCategories" v-on:update="$set(uiMods, 'hideShowAllOptionForTaskCategories', $event)" />
                <FormInput :title="'Wrap Overflowed Task Categories to Next Line'" :type="'boolean'" :value="uiMods.wrapOverflowedTaskCategories" v-on:update="$set(uiMods, 'wrapOverflowedTaskCategories', $event)" />
                <FormInput :title="'Hide Map in Chapter Screen / Task List'" :type="'boolean'" :value="uiMods.hideMapInStageScreen" v-on:update="$set(uiMods, 'hideMapInStageScreen', $event)" />
                <FormInput :title="'Use \'Back to Task List\' Button in Task Completion Pop Up'" :type="'boolean'" :helptext="'Instead of closing the pop up.'" :value="uiMods.useBackToTaskListButton" v-on:update="$set(uiMods, 'useBackToTaskListButton', $event)" />
                <FormInput :title="'Send Team Name, Team Code and Game Code upon clicking of Redirect URL'" helptext="Will be sent as URL params as teamName, teamCode and gameCode." :type="'boolean'" :value="uiMods.sendTeamInfoOnRedirect" v-on:update="$set(uiMods, 'sendTeamInfoOnRedirect', $event)" />
                <FormInput :title="'Auto-translate Content Also'" :type="'boolean'" helptext="By default, auto translation does not translate game content written by you. This will override that." :value="uiMods.autoTranslateContent" v-on:update="$set(uiMods, 'autoTranslateContent', $event)" />
                <!-- bug, will fix next time - Mo, 22 July 2024 -->
                <!-- <FormInput :title="'Single Task View for Chapters with 1 Task'" :type="'boolean'" :value="uiMods.useSingleChallengeView" v-on:update="$set(uiMods, 'useSingleChallengeView', $event)" /> -->
                <FormInput :title="'Automatically Open First Incomplete Task'" :type="'boolean'" :value="uiMods.autoOpenFirstIncompleteTask" v-on:update="$set(uiMods, 'autoOpenFirstIncompleteTask', $event)" />
                <FormInput title="Skip Completion Screen for 'No Answer' Tasks" :type="'boolean'" :value="uiMods.skipCompletionScreenNoAnswer" v-on:update="$set(uiMods, 'skipCompletionScreenNoAnswer', $event)" />
                <FormInput :title="'Skip Next Chapter Popup'" :type="'boolean'" :value="uiMods.skipNextChapterPopup" v-on:update="$set(uiMods, 'skipNextChapterPopup', $event)" />
                <FormInput :title="'Enable Thumbs Up Voting in Chats'" :type="'boolean'" :value="uiMods.enableVoting" v-on:update="$set(uiMods, 'enableVoting', $event)" />
                <FormInput :title="'Always compress image submission'" :type="'boolean'" helptext="All images submitted by players will be compressed to about 1.5 mb, helpful for places with slower or intermittent internet." :value="uiMods.alwaysCompressImageSubmission" v-on:update="$set(uiMods, 'alwaysCompressImageSubmission', $event)" />
                <FormInput :title="'Allow Teams to Change Team Name'" :type="'boolean'" :value="uiMods.allowChangeTeamName" v-on:update="$set(uiMods, 'allowChangeTeamName', $event)" />
                <FormInput :title="'Text for players to agree on'" helptext="Suitable for terms & conditions that players have to agree on before registering. Will not appear for pre-created teams." :type="'text'" :value="uiMods.textToAgreeToStart" v-on:update="$set(uiMods, 'textToAgreeToStart', $event)" />
                <FormInput
                v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')"
                :title="'Powerups Must Be Used Immediately'" :type="'boolean'" :value="uiMods.powerupsUseImmediately" v-on:update="$set(uiMods, 'powerupsUseImmediately', $event)" />
                <FormInput
                v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')"
                :title="'Show Powerups Random Card Picker'" :type="'boolean'" :value="uiMods.powerupsShowRandomPowerupsPicker" v-on:update="$set(uiMods, 'powerupsShowRandomPowerupsPicker', $event)" />
                <FormInput
                v-if="organisation.extraFeatures && organisation.extraFeatures.includes('powerups')"
                :title="'Powerups card backing image URL'" :type="'image-url'" :value="uiMods.powerupsCardBackingImageUrl" v-on:update="$set(uiMods, 'powerupsCardBackingImageUrl', $event)" />
              </div>
            </div>
          </div>
        </div>
      </article>

      <hr>

      <article v-if="stageWithBelowMaximumPoints && stageWithBelowMaximumPoints.length > 0" class="message is-warning mt-4">
        <div class="message-body warning-background-animation">
          Chapter {{ stageWithBelowMaximumPoints.length === 1 ? stageWithBelowMaximumPoints[0] : stageWithBelowMaximumPoints.slice(0, -1).join(', ') + ' and ' + stageWithBelowMaximumPoints.slice(-1) }} have "Points to complete chapter" set below the tasks total points.
          This means that it is possible for teams to complete the chapter without completing every single task.
          If this is intentional, ignore this warning. Else, please edit chapter {{ stageWithBelowMaximumPoints.length === 1 ? stageWithBelowMaximumPoints[0] : stageWithBelowMaximumPoints.slice(0, -1).join(', ') + ' and ' + stageWithBelowMaximumPoints.slice(-1) }}.
          <div class="field mt-2" @click="acknowledgeStageWithBelowMaximumPoints">
            <input id="acknowledgedStageWithBelowMaximumPoints" type="checkbox" v-model="acknowledgedStageWithBelowMaximumPoints" class="is-checkradio mr-2">
            <label for="acknowledgedStageWithBelowMaximumPoints">I have acknowledged this.</label>
          </div>
        </div>
      </article>
    </div>

    <ActiveTeamsWarningMessage :adventureId="adventureId"></ActiveTeamsWarningMessage>

    <div>
      <div class="level mb-0 mt-5">
        <div class="level-left">
          <div v-if="adventure && adventure.rawAdventureJsons" class="level-item mr-1">
            <button @click="showRestoreModal = true" class="button is-primary is-small is-outlined">
              <i class="fas fa-history mr-2"></i>
              Restore previous versions
            </button>
          </div>
          <div v-if="adventure" class="level-item mr-1">
            <DownloadAdventureAnswersButton :adventureId="adventureId" />
          </div>
          <div v-if="adventure" class="level-item">
            <DownloadGameQRCodesButton :adventureDetails="adventure.details" />
          </div>
        </div>
      </div>
      <div class="level mb-0 mt-1">
        <div class="level-left">
          <div v-if="!showMainDetailsBox && !showItemsBox && !showEndingBox && shownStageIndex === null" class="level-item">
            <router-link
              v-if="adventureId"
              :to="{name:'managePlayTour', params: { adventureId }}"
              class="button is-primary is-outlined"
            >
              <i class="fas fa-chevron-left mr-2"></i>
              Exit
            </router-link>
            <router-link
              v-else
              :to="{name:'playtours'}"
              class="button is-primary is-outlined"
            >
              <i class="fas fa-chevron-left mr-2"></i>
              Exit
            </router-link>
          </div>
          <div v-else class="level-item">
            <button @click="triggerBackButton" class="button is-primary has-text-weight-bold">
              <i class="fas fa-chevron-left fa-xs mr-2"></i>
              Back
            </button>
          </div>
        </div>
        <div class="level-right">
          <!-- removed as this was buggy - Mo - 11 Oct 2023 -->
          <!-- <div v-if="adventureId" class="level-item my-0 py-0">
            <div class="button is-primary" @click="openWelcomeScreen()" :disabled="unsavedChanges > 0">
              <i class="fas fa-home mr-2"></i>
              Preview welcome screen
            </div>
          </div> -->
          <div v-if="shownStageIndex !== null" class="level-item">
            <div
              v-if="adventureId"
              @click="openTestPage(shownStageIndex + 1)"
              class="button is-primary is-light"
              v-bind:class="{'is-loading': loading}"
            >
              <i class="fas fa-external-link-alt mr-2"></i>
              {{`Test Chapter ${shownStageIndex + 1}`}}
            </div>
          </div>
          <div v-if="adventureId && shownStageIndex === null" class="level-item my-0 py-0">
            <div class="button is-primary" @click="openTestGame()" :disabled="unsavedChanges > 0">
              <i class="fas fa-vial mr-2"></i>
              Test game
            </div>
          </div>
          <div v-if="message && !error" class="level-item my-0 py-0">
            <div class="is-size-6 has-text-success">
              {{ adventureId ? 'Successfully edited game.' : 'Successfully created game.' }}
            </div>
            <div v-if="error" class="is-size-6 has-text-danger">
              Errors found, please fix the fields highlighted in red above.
            </div>
          </div>
          <div v-if="!adventureId && !error" class="has-text-grey mr-3">
            You can test the game & continue editing after clicking "Create game"
          </div>
          <div class="level-item my-0 py-0">
            <div
            @click="createAdventure(null)"
            class="button is-primary"
            :disabled="adventureId && savedPlayTours"
            v-bind:class="{'is-loading': loading}">
              <i class="fas fa-save mr-2"></i>
              {{ adventureId ? 'Save changes' : 'Create game & start testing' }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="adventure && adventure.rawAdventureJsons" class="modal" :class="{'is-active': showRestoreModal}">
        <div class="modal-background is-clickable" @click="showRestoreModal = false"></div>
        <div class="modal-card" style="max-width: 700px; width: 90%;">
          <header class="modal-card-head">
            <p class="modal-card-title">Restore previous versions</p>
            <button class="delete is-clickable" aria-label="close" @click="showRestoreModal = false"></button>
          </header>
          <section class="modal-card-body">
            Your past saves can be restored. Your current unsaved changes will be replaced. After restoring, click "Save Changes" to confirm.
            <div class="mt-4 buttons">
              <button @click="restoreBackup(backup.rawAdventureJson)" v-for="backup, index in adventure.rawAdventureJsons" :key="index"
              class="button is-small is-rounded mb-2 is-clickable"
              type="button"
              >
              {{ new Date(backup.createdAt.seconds * 1000).toLocaleString('en-SG') }}
              </button>
            </div>
          </section>
        </div>
        <button class="modal-close is-large is-clickable" aria-label="close" @click="showRestoreModal = false"></button>
      </div>
    </div>
    <CopyChallenges
      :challenge="copyChallengesChallenge"
      v-on:close="copyChallengesChallenge = null"
      v-on:setChallenge="copyChallengeToChallenge($event)"
    />
    <CreatePlayToursSupportMessage />
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import FormInput from '@/components/Forms/FormInput'
import LatLonInput from '@/components/Forms/LatLonInput'
import PanellumXYInput from '@/components/Forms/PanellumXYInput'
import CardComponent from '@/components/CardComponent'
// import FaqAiChat from '@/components/FaqAiChat'
import DownloadAdventureAnswersButton from '@/components/DownloadAdventureAnswersButton'
import DownloadGameQRCodesButton from '@/components/DownloadGameQRCodesButton'
import CopyChallenges from './CopyChallenges.vue'
import { answerNewDatas } from '../utils/answerNewDatas'
import draggable from 'vuedraggable'
import ActiveTeamsWarningMessage from './ActiveTeamsWarningMessage.vue'
import TaskCategoriesManager from './TaskCategoriesManager.vue'
import ChapterTutorialModal from '@/components/ChapterTutorialModal'
import PlayToursAI from './PlayToursAI.vue'
import CreatePlayToursSupportMessage from '@/components/CreatePlayToursSupportMessage.vue'

const searchableItemsNewData = {
  item: '',
  virtual_space_image_url: null,
  scanCode: null,
  lat: null,
  lon: null,
  radius_in_meters: null,
  shown_on_map: null,
  stage: null,
  minutes: null,
  points: null
}

const challengeNewData = {
  name: 'Task 1',
  briefing: '',
  points: 5,
  wrongAnswerPenalty: null,
  timePenalty: null,
  skippable: false,
  skipPenalty: null,
  skippableAfterXTries: null,
  hasItem: false,
  item: null,
  type: '',
  hasHint: false,
  hint: null,
  hasCustomCompletionMessage: false,
  customCompletionMessage: null,
  hasCustomFailureMessage: false,
  customFailureMessage: null,
  hasCustomJudgementMessage: false,
  customJudgementMessage: null,
  showSpecificPlayersOnly: null,
  maxCompletionsPerSession: null,
  accessCode: null,
  answers: []
}

const virtualSpaceNewData = {
  name: null,
  imageUrl: null,
  adjacentScenes: []
}

const adjacentScenesNewData = {
  name: null,
  y: null,
  x: null,
  imageUrl: null,
  taskNumber: null
}

const stageNewData = {
  show: false,
  title: null,
  briefing: '',
  timeLimitInMinutes: null,
  autoForwardWhenTimeIsUp: null,
  mustCompleteAllTasks: true,
  completeChallengesInOrder: true,
  shuffleChallenges: false,
  shuffleChallengesPreserveSequence: false,
  minPoints: null,
  chapterBackgroundUrl: null,
  chapterMusicUrl: null,
  hasVirtualSpaces: false,
  virtualSpaces: [{
    name: null,
    imageUrl: null,
    adjacentScenes: [{
      name: null,
      y: null,
      x: null,
      imageUrl: null
    }]
  }],
  challenges: [challengeNewData]
}

export default {
  name: 'PlayTourCreate',
  components: {
    CardComponent,
    FormInput,
    LatLonInput,
    PanellumXYInput,
    // FaqAiChat,
    DownloadAdventureAnswersButton,
    DownloadGameQRCodesButton,
    CopyChallenges,
    draggable,
    ActiveTeamsWarningMessage,
    TaskCategoriesManager,
    ChapterTutorialModal,
    PlayToursAI,
    CreatePlayToursSupportMessage
  },
  props: {
    adventureId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      adventure: null,
      jsonStr: null,
      cleanedRawAdventureJson: '',
      unsavedChanges: 0,
      acknowledgedStageWithBelowMaximumPoints: false,
      mountedAt: new Date().getTime(),

      message: '',
      loading: false,
      error: false,
      errorsObj: null,
      showFaqAiChat: false,
      showRestoreModal: false,
      showMainDetailsBox: false,
      showItemsBox: false,
      showEndingBox: false,
      shownChallenge: {
        chapterIndex: null,
        challengeIndex: null
      },
      shownItem: null,
      panellumData: null,
      showVirtualSpace: false,
      viewer: null,
      uiModsCategoryFilter: null,

      showOrganisationOnly: false,
      organisationId: null,
      isPlayable: true,
      name: '',
      previewImageUrl: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2Fplaytours-cover-photo-placeholder.jpg?alt=media&token=3d898f0d-98e0-4449-b4c3-f11ea695fda3',
      tags: '',
      shuffleChapters: false,
      shuffleChaptersNotFirstLast: false,
      shuffleChaptersPreserveSequence: false,
      preventBunching: false,
      hasStartingLocationLatLon: true,
      startingLocationName: '',
      lat: null,
      lon: null,
      radiusInMeters: null,
      endingLocationName: null,
      hasEndingLocationLatLon: false,
      endingLocationLatLon: null,
      briefing: '',
      debrief: '',
      allowTranslation: false,
      defaultLanguage: null,
      redirectUrl: null,
      validStartDatetimes: [{
        dateFrom: null,
        dateTo: null,
        day: null,
        datetimeFrom: null,
        datetimeTo: null
      }],
      timeLimitInMinutes: null,
      endAfterTimeLimit: false,
      startingCredits: null,
      stages: [JSON.parse(JSON.stringify(stageNewData))],
      searchableItems: [],
      uiMods: {
        hideTimingsLeaderboard: true,
        showAdventureIntroInLobby: !this.adventureId
      },
      openedTaskTypeTab: null,

      copyChallengesChallenge: null,

      screenshots: {
        1: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F1.png?alt=media&token=b4446dd3-0555-4e90-8fc4-7655ff2c464a',
        2: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F2.png?alt=media&token=46692fdf-affd-4b67-b5d6-15120c5ed225',
        3: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F3.png?alt=media&token=5cb8d614-93b6-444d-ab96-573a8f588bb2',
        4: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F4.png?alt=media&token=07a36e49-5166-4253-9c3e-2cf72b4f0a49',
        5: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F5.png?alt=media&token=82b3c04b-a09f-4e35-b20e-f5c2803e441e',
        6: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F6.png?alt=media&token=ed845ed5-c7ff-479a-a41b-4683f53d8c35',
        7: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F7.png?alt=media&token=61726ad3-fef7-4e81-90cc-9c3c451ec845',
        8: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F8.png?alt=media&token=b215733f-5877-4b11-a0bd-d50ea98c0e3c',
        9: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F9.png?alt=media&token=69cec181-5a58-4df8-909b-95f34866122f',
        10: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F10.png?alt=media&token=46be510a-a00b-4ddc-bd41-b91f1c53b2c0',
        11: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F11.png?alt=media&token=d9ea24b6-046f-4f83-9240-763c75436046',
        12: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F12.png?alt=media&token=71ce0b84-f911-4c6e-91f8-f220efdfdec8',
        13: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F13.png?alt=media&token=4e8a444d-ee80-4101-a955-cb91da94ec49',
        14: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F14.png?alt=media&token=15607cb9-379b-4e47-a51e-ae50f4c789fa',
        15: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F15.png?alt=media&token=6d8e5f67-4f71-4e1f-8f98-71a35b3cb676',
        16: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F16.png?alt=media&token=4a5d1e26-fca1-43fc-82b8-71d26cdb5919',
        17: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F17.png?alt=media&token=8497d457-60bb-4a88-bcaa-c929edd60174',
        18: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F18.png?alt=media&token=3e62dff6-7e34-4250-a854-b342cf888007',
        19: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FEdit%20PlayTour%20Guidance%20Screenshots%2F19.png?alt=media&token=7aa75897-58fd-4dab-aac6-816b4ffa702a'
      },
      taskTypeSelectionsxxxxxx: [
        { type: 'no-answer', desc: 'Players only have to click "Mark as completed"', icon: 'fas fa-check-circle', categories: [null, 'Others'] },
        { type: 'text', desc: 'Players submit text as the answer', icon: 'fas fa-font', categories: [null, 'Text'] },
        { type: 'image', desc: 'Upload/snap a photo, all answers marked as correct', icon: 'fas fa-camera', categories: [null, 'Photo'] },
        { type: 'judged-image', desc: 'Upload/snap a photo, facilitator must validate the answer', icon: 'fas fa-gavel', categories: [null, 'Photo'] },
        { type: 'video', desc: 'Upload/snap a video, all answers marked as correct', icon: 'fas fa-video', categories: [null, 'Video'] },
        { type: 'judged-video', desc: 'Upload/snap a video, facilitator must validate the answer', icon: 'fas fa-gavel', categories: [null, 'Video'] },
        { type: 'direction', desc: 'Check-in using GPS, location is shown to user. Use this to bring players to a location.', icon: 'fas fa-map-marked-alt', categories: [null, 'GPS'] },
        { type: 'qrbarcode', desc: 'Scan a QR code or barcode', icon: 'fas fa-qrcode', categories: [null, 'Others'] },
        { type: 'multiple-choice', desc: 'Select 1 or more correct answers', icon: 'fas fa-list-ul', categories: [null, 'Others'] },
        { type: 'free-multiple-choice', desc: 'Select 1 or more options, no right or wrong answers. Typically for surveys.', icon: 'far fa-list-alt', categories: ['Others'] },
        { type: 'word-search', desc: 'Search through scrambled letters for a given list of words', icon: 'fas fa-search', categories: ['Puzzle'] },
        { type: 'matching-pairs', desc: 'Match words/statements/images on the left with the ones on the right', icon: 'fas fa-exchange-alt', categories: ['Puzzle'] },
        { type: 'scrambled-phrase', desc: 'Re-arrange a scrambled phrase', icon: 'fas fa-random', categories: ['Puzzle'] },
        { type: 'image-jigsaw', desc: 'Solve a scrambled image just like a jigsaw puzzle', icon: 'fas fa-puzzle-piece', categories: ['Puzzle'] },
        { type: 'combination-lock', desc: 'Solve a digital combination lock', icon: 'fas fa-lock', categories: ['Puzzle'] },
        { type: 'puzzles', desc: 'Crossword puzzles, memory game, and so much more', icon: 'fas fa-brain', categories: ['Puzzle'] },
        { type: 'text-ai', desc: '(NEW) Use AI to validate a text answer using your own prompts', icon: 'fas fa-robot', categories: ['Text'] },
        { type: 'fill-in-the-blanks', desc: 'Text answer in fill-in-the-blanks format', icon: 'fas fa-i-cursor', categories: ['Text'] },
        { type: 'judged-text', desc: 'Text answer, facilitator must validate the answer', icon: 'fas fa-gavel', categories: ['Text'] },
        { type: 'free-text', desc: 'Text answer, all answers marked as correct', icon: 'fas fa-keyboard', categories: ['Text'] },
        { type: 'text-share', desc: 'Text answer, all answers marked as correct and shared with everyone in the session', icon: 'fas fa-bullhorn', categories: ['Text'] },
        { type: 'number', desc: 'Number-based answer, with decimals', icon: 'fas fa-calculator', categories: ['Number'] },
        { type: 'integer', desc: 'Integer-based answer, without decimals', icon: 'fas fa-calculator', categories: ['Number'] },
        { type: 'image-multiple', desc: 'Upload/snap 1 or several photos, all answers marked as correct', icon: 'fas fa-images', categories: ['Photo'] },
        { type: 'image-share', desc: 'Upload/snap a photo, all answers marked as correct and shared with everyone in the session', icon: 'fas fa-share-square', categories: ['Photo'] },
        { type: 'judged-image-ai', desc: 'Upload/snap a photo, and use an AI prompt to check if it fits a description', icon: 'fas fa-robot', categories: ['Photo'] },
        { type: 'image-similarity', desc: 'Upload/snap a photo, answer must be similar to an image you uploaded', icon: 'fas fa-clone', categories: ['Photo'] },
        { type: 'object-recognition', desc: 'Upload/snap a photo, must contain an object you specify', icon: 'fas fa-eye', categories: ['Photo'] },
        { type: 'audio', desc: 'Record audio, all answers marked as correct', icon: 'fas fa-microphone', categories: ['Others'] },
        { type: 'judged-audio', desc: 'Record audio, facilitator must validate the answer', icon: 'fas fa-headphones', categories: ['Others'] },
        { type: 'audio-words-recognition', desc: 'Record audio, and it must match the word(s) you specify in a specific language', icon: 'fas fa-spell-check', categories: ['Others'] },
        { type: 'location', desc: 'Check-in using GPS, location is NOT shown to user. Use this if players need to find a location based on clues, etc.', icon: 'fas fa-compass', categories: ['GPS'] },
        { type: 'file', desc: 'Upload a file (image, video, .pdf, .zip, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt), all answers marked as correct', icon: 'fas fa-file', categories: ['Others'] },
        { type: 'judged-file', desc: 'Upload a file (image, video, .pdf, .zip, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt), facilitator must validate the answer', icon: 'fas fa-gavel', categories: ['Others'] },
        { type: 'free-location', desc: 'Players can submit any GPS location as their answer', icon: 'fas fa-map-marker-alt', categories: ['GPS'] }
      ],
      defaultLanguageSelectionssss: [
        { key: 'None', value: null },
        { key: 'Amharic', value: 'am' },
        { key: 'Arabic', value: 'ar' },
        { key: 'Basque', value: 'eu' },
        { key: 'Bengali', value: 'bn' },
        { key: 'Bulgarian', value: 'bg' },
        { key: 'Catalan', value: 'ca' },
        { key: 'Cherokee', value: 'chr' },
        { key: 'Croatian', value: 'hr' },
        { key: 'Czech', value: 'cs' },
        { key: 'Danish', value: 'da' },
        { key: 'Dutch', value: 'nl' },
        { key: 'English (UK)', value: 'en-GB' },
        { key: 'English (US)', value: 'en' },
        { key: 'Estonian', value: 'et' },
        { key: 'Filipino', value: 'fil' },
        { key: 'Finnish', value: 'fi' },
        { key: 'French', value: 'fr' },
        { key: 'German', value: 'de' },
        { key: 'Greek', value: 'el' },
        { key: 'Gujarati', value: 'gu' },
        { key: 'Hebrew', value: 'iw' },
        { key: 'Hindi', value: 'hi' },
        { key: 'Hungarian', value: 'hu' },
        { key: 'Icelandic', value: 'is' },
        { key: 'Indonesian', value: 'id' },
        { key: 'Italian', value: 'it' },
        { key: 'Japanese', value: 'ja' },
        { key: 'Kannada', value: 'kn' },
        { key: 'Korean', value: 'ko' },
        { key: 'Latvian', value: 'lv' },
        { key: 'Lithuanian', value: 'lt' },
        { key: 'Malay', value: 'ms' },
        { key: 'Malayalam', value: 'ml' },
        { key: 'Marathi', value: 'mr' },
        { key: 'Norwegian', value: 'no' },
        { key: 'Polish', value: 'pl' },
        { key: 'Portuguese (Portugal)', value: 'pt-PT' },
        { key: 'Portuguese (Brazil)', value: 'pt-BR' },
        { key: 'Romanian', value: 'ro' },
        { key: 'Russian', value: 'ru' },
        { key: 'Serbian', value: 'sr' },
        { key: 'Chinese (PRC)', value: 'zh-CN' },
        { key: 'Slovak', value: 'sk' },
        { key: 'Slovenian', value: 'sl' },
        { key: 'Spanish', value: 'es' },
        { key: 'Swahili', value: 'sw' },
        { key: 'Swedish', value: 'sv' },
        { key: 'Tamil', value: 'ta' },
        { key: 'Telugu', value: 'te' },
        { key: 'Thai', value: 'th' },
        { key: 'Chinese (Taiwan)', value: 'zh-TW' },
        { key: 'Turkish', value: 'tr' },
        { key: 'Urdu', value: 'ur' },
        { key: 'Ukrainian', value: 'uk' },
        { key: 'Vietnamese', value: 'vi' },
        { key: 'Welsh', value: 'cy' }
      ],
      showUiModsBox: false,
      gameBuilderActivityInterval: null
    }
  },
  methods: {
    openPage (url) {
      window.open(url)
    },
    openTestPage (stage) {
      this.createAdventure(stage)
    },
    openWelcomeScreen () {
      if (this.unsavedChanges > 0) {
        alert('Please save changes first.')
        return
      }
      const link = `${this.$store.state.baseUrl}/play/join/${this.adventureId}/`
      window.open(link)
    },
    openTestGame () {
      if (this.unsavedChanges > 0) {
        alert('Please save changes first.')
        return
      }
      const confirmed = confirm('All location checks are removed, so you can complete GPS tasks, and view tasks that are location-based wherever you are.')
      if (!confirmed) { return }
      const link = `${this.$store.state.baseUrl}/play/join/${this.adventureId}/TESTING-1`
      window.open(link)
    },
    reset (needConfirmation) {
      if (needConfirmation) {
        const confirmed = confirm('All fields will be reset. Confirm?')
        if (!confirmed) {
          return
        }
      }

      this.name = ''
      this.previewImageUrl = 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2Fplaytours-cover-photo-placeholder.jpg?alt=media&token=3d898f0d-98e0-4449-b4c3-f11ea695fda3'
      this.tags = ''
      this.hasStartingLocationLatLon = true
      this.startingLocationName = ''
      this.lat = null
      this.lon = null
      this.radiusInMeters = null
      this.endingLocationName = null
      this.hasEndingLocationLatLon = false
      this.endingLocationLatLon = null
      this.briefing = ''
      this.debrief = ''
      this.redirectUrl = null
      this.validStartDatetimes = [{
        dateFrom: null,
        dateTo: null,
        day: null,
        datetimeFrom: null,
        datetimeTo: null
      }]
      this.timeLimitInMinutes = null
      this.endAfterTimeLimit = false
      this.startingCredits = null
      this.shuffleChapters = false
      this.shuffleChaptersPreserveSequence = false
      this.preventBunching = false
      this.stages = [JSON.parse(JSON.stringify(stageNewData))]

      this.error = false
      this.errorsObj = null
      this.message = null
      this.showMainDetailsBox = false
      this.showItemsBox = false
      this.stages = []
      this.searchableItems = []

      localStorage.removeItem('monabrunJson')
    },
    duplicateStage (stageIndex) {
      const stagesDuplicate = JSON.parse(JSON.stringify(this.stages))
      stagesDuplicate.splice(stageIndex, 0, this.stages[stageIndex])
      stagesDuplicate[stageIndex + 1].title = stagesDuplicate[stageIndex + 1].title ? stagesDuplicate[stageIndex + 1].title + ' COPY' : ' COPY'
      this.stages = stagesDuplicate
      this.$buefy.toast.open({
        message: 'Chapter duplicated',
        type: 'is-primary',
        queue: false
      })
    },
    duplicateChallenge (stageIndex, challengeIndex, openNewChallenge) {
      const challengeDuplicate = JSON.parse(
        JSON.stringify(this.stages[stageIndex].challenges[challengeIndex]))

      if (challengeDuplicate.name.match(/\d+$/) && !challengeDuplicate.name.includes(' of ')) {
        challengeDuplicate.name = challengeDuplicate.name.replace(/\d+$/, match => parseInt(match) + 1)
      } else if (challengeDuplicate.name.match(/\d+ of \d+$/)) {
        const [current] = challengeDuplicate.name.match(/\d+/g)
        challengeDuplicate.name = challengeDuplicate.name.replace(current, match => parseInt(match) + 1)
      } else {
        challengeDuplicate.name += ' COPY'
      }
      this.stages[stageIndex].challenges.splice(challengeIndex + 1, 0, challengeDuplicate)
      this.$buefy.toast.open({
        message: 'Task duplicated',
        type: 'is-primary',
        queue: false
      })
      if (openNewChallenge) {
        this.expandChallenge(stageIndex, this.stages[stageIndex].challenges.length - 1)
      }
    },
    expandChallenge (stageIndex, challengeIndex) {
      if (this.unsavedChanges <= 0) this.unsavedChanges = -1
      const challenge = this.shownChallenge.stageIndex != null ? this.stages[this.shownChallenge.stageIndex]
        .challenges[this.shownChallenge.challengeIndex] : null
      // if (
      //   challenge &&
      //   challenge.type &&
      //   (
      //     challenge.answers.length === 0 ||
      //     (challenge.answers.length === 1 && (!challenge.answers[0].value && !challenge.answers[0].lat))
      //   ) &&
      //   ![
      //     'no-answer', 'free-text', 'text-share', 'image', 'image-multiple', 'image-share', 'judged-image',
      //     'video', 'judged-video', 'audio', 'judged-audio', 'judged-text'
      //   ].includes(challenge.type)
      // ) {
      //   alert('Add at least 1 possible answer - scroll to the bottom of the "Details" tab and go to "Accepted Answers".')
      //   return
      // }
      if (
        challenge &&
        ['word-search'].includes(challenge.type) &&
        challenge.answers.some(answer => answer.value.length === 0 || answer.value.length > 10)
      ) {
        alert('Words must only be between 1-10 characters long.')
        return
      }

      this.$set(this.shownChallenge, 'stageIndex', stageIndex)
      this.$set(this.shownChallenge, 'challengeIndex', challengeIndex)
      this.$set(this.shownChallenge, 'tabOpened', this.shownChallenge ? this.shownChallenge.tabOpened : null)
    },
    copyChallengeToChallenge (newChallenge) {
      this.$set(
        this.stages[
          this.shownChallenge.stageIndex].challenges,
        this.shownChallenge.challengeIndex, newChallenge
      )
    },
    expandItem (itemIndex) {
      this.shownItem = itemIndex
    },
    handleTaskTypeChange (challenge, refName) {
      this.$refs[refName][0].blur()
      if (!confirm('Confirm task type change?')) return
      const newStages = [...this.stages]
      newStages[this.shownChallenge.stageIndex].challenges[this.shownChallenge.challengeIndex].type = null
      this.stages = newStages
    },
    changeTaskTab (tabName) {
      this.$set(this.shownChallenge, 'tabOpened', tabName)
    },
    changeStageSequence (index, direction) {
      if (direction === -1 || direction === 1) {
        const stagesClone = JSON.parse(JSON.stringify(this.stages))
        const item = stagesClone.splice(index, 1)
        stagesClone.splice((index + direction), 0, item[0])
        this.stages = stagesClone
        this.$buefy.toast.open({
          message: 'Chapter re-arranged',
          type: 'is-primary',
          queue: false
        })
      }
    },
    changeChallengeSequence (stageIndex, challengeIndex, direction) {
      if (direction === -1 || direction === 1) {
        const stagesClone = JSON.parse(JSON.stringify(this.stages))
        const item = stagesClone[stageIndex].challenges.splice(challengeIndex, 1)
        if (
          direction === -1 &&
          challengeIndex === 0
        ) {
          stagesClone[stageIndex - 1].challenges.push(item[0])
          this.toggleShowStage(stageIndex - 1)
        } else if (
          direction === 1 &&
          challengeIndex === stagesClone[stageIndex].challenges.length
        ) {
          stagesClone[stageIndex + 1].challenges.unshift(item[0])
          this.toggleShowStage(stageIndex + 1)
        } else {
          stagesClone[stageIndex].challenges.splice((challengeIndex + direction), 0, item[0])
        }
        this.stages = stagesClone
        this.$buefy.toast.open({
          message: 'Task re-arranged',
          type: 'is-primary',
          queue: false
        })
        if (challengeIndex === 0 && direction === -1) {
          this.toggleShowStage(stageIndex - 1)
        } else if (challengeIndex === stagesClone[stageIndex].challenges.length && direction === 1) {
          this.toggleShowStage(stageIndex + 1)
        }
      }
    },
    addToSearchableItemsArray (arrayFieldName, objectData) {
      this.searchableItems.push(JSON.parse(JSON.stringify(searchableItemsNewData)))
    },
    deleteFromArray (arrayFieldName, index, min) {
      if (!confirm('Confirm deletion?')) return
      if (this[arrayFieldName].length > min) {
        this[arrayFieldName].splice(index, 1)
      }
    },
    addToChallengeArray (stageIndex, openNewChallenge) {
      const challengeNewDataEdited = JSON.parse(JSON.stringify(challengeNewData))
      challengeNewDataEdited.name = `Task ${this.stages[stageIndex].challenges.length + 1}`
      this.stages[stageIndex].challenges.push(challengeNewDataEdited)
      if (openNewChallenge) {
        this.expandChallenge(stageIndex, this.stages[stageIndex].challenges.length - 1)
      }
    },
    deleteChallenge (stageIndex, challengeIndex) {
      const confirmed = confirm('Confirm deletion?')
      if (!confirmed) { return }
      if (this.stages[stageIndex].challenges.length > 1) {
        this.stages[stageIndex].challenges.splice(challengeIndex, 1)
      }
    },
    addScene (stageIndex) {
      this.stages[stageIndex].virtualSpaces.push(JSON.parse(JSON.stringify(virtualSpaceNewData)))
    },
    deleteScene (stageIndex, sceneIndex) {
      const confirmed = confirm('Confirm deletion?')
      if (!confirmed) { return }
      if (this.stages[stageIndex].virtualSpaces.length > 1) {
        this.stages[stageIndex].virtualSpaces.splice(sceneIndex, 1)
      }
    },
    deleteAdjacentScene (stageIndex, sceneIndex, adjacentSceneIndex) {
      const confirmed = confirm('Confirm deletion?')
      if (!confirmed) { return }
      this.stages[stageIndex].virtualSpaces[sceneIndex].adjacentScenes.splice(adjacentSceneIndex, 1)
    },
    addAdjacentScene (stageIndex, sceneIndex) {
      this.stages[stageIndex].virtualSpaces[sceneIndex].adjacentScenes.push(JSON.parse(JSON.stringify(adjacentScenesNewData)))
    },
    toggleVirtualSpace (stageIndex) {
      if (this.showVirtualSpace) {
        this.showVirtualSpace = false
      } else {
        const scenes = {}
        this.stages[stageIndex].virtualSpaces.forEach(virtualSpace => {
          scenes[virtualSpace.name] = {
            title: virtualSpace.name,
            hfov: 360,
            pitch: 0,
            yaw: 0,
            type: 'equirectangular',
            panorama: virtualSpace.imageUrl,
            hotSpots: virtualSpace.adjacentScenes.map(adjacentScene => {
              return {
                title: adjacentScene.name,
                pitch: adjacentScene.y,
                yaw: adjacentScene.x,
                type: 'scene',
                text: adjacentScene.name,
                sceneId: !adjacentScene.imageUrl ? adjacentScene.name : null,
                imageUrl: adjacentScene.imageUrl,
                cssClass: adjacentScene.imageUrl ? 'magnifying-glass' : null,
                clickHandlerFunc: () => {
                  if (adjacentScene.imageUrl && adjacentScene.imageUrl.length > 0) {
                    this.showViewerJs(adjacentScene.imageUrl)
                  }
                }
              }
            })
          }
        })
        this.panellumData = {
          default: {
            firstScene: this.stages[stageIndex].virtualSpaces[0].name,
            sceneFadeDuration: 500
          },
          scenes
        }
        this.showVirtualSpace = true
      }
    },
    addStage () {
      this.stages.push(JSON.parse(JSON.stringify(stageNewData)))
      this.toggleShowStage(this.stages.length - 1)
    },
    deleteStage (stageIndex) {
      const confirmed = confirm('Confirm deletion?')
      if (!confirmed) { return }
      if (this.stages.length > 1) {
        this.stages.splice(stageIndex, 1)
      }
    },
    toggleHint (stageIndex, challengeIndex, hasHint) {
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hasHint', hasHint)
      if (hasHint) {
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hint', {
          hint: '',
          credits: null,
          revealAfterXTries: null
        })
      } else {
        const confirmed = confirm('This will delete the hint and cannot be undone, confirm?')
        if (!confirmed) { return }
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hint', null)
      }
    },
    toggleHasItem (stageIndex, challengeIndex, hasItem) {
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hasItem', hasItem)
      if (hasItem) {
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'item', '')
      } else {
        const confirmed = confirm('This will delete the item and cannot be undone, confirm?')
        if (!confirmed) { return }
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'item', null)
      }
    },
    toggleCompletionMessage (stageIndex, challengeIndex, hasCustomCompletionMessage) {
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hasCustomCompletionMessage', hasCustomCompletionMessage)
      if (hasCustomCompletionMessage) {
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'customCompletionMessage', '')
      } else {
        const confirmed = confirm('This will delete the completion message and cannot be undone, confirm?')
        if (!confirmed) { return }
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'customCompletionMessage', null)
      }
    },
    toggleFailureMessage (stageIndex, challengeIndex, hasCustomFailureMessage) {
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hasCustomFailureMessage', hasCustomFailureMessage)
      if (hasCustomFailureMessage) {
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'customFailureMessage', '')
      } else {
        const confirmed = confirm('This will delete the failure message and cannot be undone, confirm?')
        if (!confirmed) { return }
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'customFailureMessage', null)
      }
    },
    toggleJudgementMessage (stageIndex, challengeIndex, hasCustomJudgementMessage) {
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'hasCustomJudgementMessage', hasCustomJudgementMessage)
      if (hasCustomJudgementMessage) {
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'customJudgementMessage', '')
      } else {
        const confirmed = confirm('This will delete the judgement message and cannot be undone, confirm?')
        if (!confirmed) { return }
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'customJudgementMessage', null)
      }
    },
    togglePositionBasedTask (stageIndex, challengeIndex, isPositionBased) {
      if (isPositionBased) {
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'position', {
          lat: null,
          lon: null,
          radius_in_meters: 40,
          visibleOutsideRadius: true,
          allowCompletionOutsideRadius: false,
          shownOnMap: true
        })
      } else {
        const confirmed = confirm('This will delete the position data, confirm?')
        if (!confirmed) { return }
        this.$set(this.stages[stageIndex].challenges[challengeIndex], 'position', null)
      }
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'isPositionBased', isPositionBased)
    },
    toggleHasEndingLocationLatLon (hasEndingLocationLatLon) {
      if (hasEndingLocationLatLon) {
        this.endingLocationLatLon = {
          lat: null,
          lon: null,
          radius_in_meters: null
        }
      } else {
        this.endingLocationLatLon = null
      }
    },
    handleChangeTaskTypeClick (taskType, stageIndex, challengeIndex) {
      if (this.$posthog) this.$posthog.capture(`selected_task_type_${taskType}`)
      if (taskType === 'puzzles') {
        this.openPage('https://www.playtours.app/post/how-to-add-crosswords-word-search-jigsaw-puzzles-and-more')
        return
      }
      const newStages = [...this.stages]
      newStages[stageIndex].challenges[challengeIndex].type = taskType
      this.resetAnswers(stageIndex, challengeIndex)
      if (taskType === 'qrbarcode') {
        const answerNewData = answerNewDatas[taskType]
        answerNewData.value = crypto.randomUUID()
        newStages[stageIndex].challenges[challengeIndex].answers.push(
          JSON.parse(JSON.stringify(answerNewData))
        )
      }
      this.stages = newStages
    },
    resetAnswers (stageIndex, challengeIndex) {
      if (this.stages[stageIndex].challenges[challengeIndex].answers.length > 0) {
        const type = this.stages[stageIndex].challenges[challengeIndex].type
        if (['judged-image', 'judged-video', 'judged-text'].includes(type)) {
          this.stages[stageIndex].challenges[challengeIndex].answers = []
        } else {
          this.stages[stageIndex].challenges[challengeIndex].answers = [JSON.parse(JSON.stringify(answerNewDatas[type]))]
        }
      }
    },
    addToAnswersArray (stageIndex, challengeIndex) {
      const type = this.stages[stageIndex].challenges[challengeIndex].type
      this.stages[stageIndex].challenges[challengeIndex].answers.push(
        JSON.parse(JSON.stringify(answerNewDatas[type]))
      )
    },
    deleteAnswer (stageIndex, challengeIndex, AnswerIndex) {
      const confirmed = confirm('Confirm deletion?')
      if (!confirmed) { return }
      if (this.stages[stageIndex].challenges[challengeIndex].answers.length > 1) {
        this.stages[stageIndex].challenges[challengeIndex].answers.splice(AnswerIndex, 1)
      }
    },
    toggleMainDetailsBox () {
      this.showMainDetailsBox = !this.showMainDetailsBox
      this.showItemsBox = false
      this.stages.forEach(el => {
        el.show = false
      })
    },
    toggleItemsBox () {
      this.showMainDetailsBox = false
      this.showItemsBox = !this.showItemsBox
      this.showEndingBox = false
      this.stages.forEach(el => {
        el.show = false
      })
    },
    toggleShowStage (index) {
      if (this.unsavedChanges <= 0) this.unsavedChanges = -1
      this.shownChallenge = {
        chapterIndex: null,
        challengeIndex: null
      }
      this.showMainDetailsBox = false
      this.showItemsBox = false
      this.showEndingBox = false
      if (index !== null) {
        this.$set(this.stages[index], 'show', !this.stages[index].show)
        this.stages.forEach((el, i) => {
          if (index !== i) {
            el.show = false
          }
        })
        this.stages = [...this.stages]
      }
    },
    toggleEndingBox () {
      this.showMainDetailsBox = false
      this.showItemsBox = false
      this.showEndingBox = !this.showEndingBox
      this.stages.forEach(el => {
        el.show = false
      })
    },
    triggerBackButton () {
      if (this.showMainDetailsBox) this.toggleMainDetailsBox()
      if (this.showEndingBox) this.toggleEndingBox()
      this.toggleShowStage(this.shownStageIndex)
    },
    showViewerJs (imageUrl) {
      this.viewer = this.$viewerApi({
        images: [imageUrl],
        options: {
          navbar: false,
          title: false,
          button: true
        }
      })
    },
    createAdventure (testPageStage) {
      if (this.adventureId && this.unsavedChanges === 0 && !testPageStage) {
        alert('No changes made.')
        return
      }
      if (
        this.stageWithBelowMaximumPoints &&
        this.stageWithBelowMaximumPoints.length > 0 &&
        !this.acknowledgedStageWithBelowMaximumPoints) {
        this.triggerBackButton()
        alert('Please review the warning in the yellow box and acknowledge it before saving changes.')
        return
      }
      this.message = null
      this.loading = true
      this.error = false
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      const adventureData = {
        show_organisation_only: this.showOrganisationOnly,
        organisation_id: null,
        is_playable: this.isPlayable,
        adventure_details: {
          name: this.name,
          preview_image_url: this.previewImageUrl,
          tags: this.tags,
          starting_location_name: this.hasStartingLocationLatLon ? this.startingLocationName : null,
          starting_location_lat_lon: this.hasStartingLocationLatLon ? {
            lat: this.lat,
            lon: this.lon,
            radius_in_meters: this.radiusInMeters
          } : null,
          ending_location_name: this.endingLocationName,
          ending_location_lat_lon: this.endingLocationLatLon,
          briefing: this.briefing,
          debrief: this.debrief,
          redirect_url: this.redirectUrl,
          estimated_duration_in_minutes: this.estimatedDurationInMinutes,
          allow_translation: this.allowTranslation,
          default_language: this.defaultLanguage,
          shuffle_chapters: this.shuffleChapters,
          shuffle_chapters_not_first_last: this.shuffleChaptersNotFirstLast,
          shuffle_chapters_preserve_sequence: this.shuffleChaptersPreserveSequence,
          prevent_bunching: this.preventBunching,
          valid_start_datetimes: this.validStartDatetimes.map(x => {
            return {
              date_from: x.dateFrom,
              date_to: x.dateTo,
              day: x.day,
              time_from: x.datetimeFrom,
              time_to: x.datetimeTo
            }
          }),
          time_limit_in_minutes: this.timeLimitInMinutes,
          end_after_time_limit: this.endAfterTimeLimit,
          starting_credits: this.startingCredits
        },
        uiMods: this.uiMods,
        stages: this.stages.map((x, index) => {
          let minPoints = x.minPoints
          if (!x.showMinPoints && !x.minPoints) {
            minPoints = this.calculateTotalTaskPoints(index)
          }
          return {
            title: x.title,
            briefing: x.briefing,
            time_limit_in_minutes: x.timeLimitInMinutes,
            auto_forward_when_time_is_up: x.autoForwardWhenTimeIsUp,
            min_points: minPoints,
            complete_challenges_in_order: x.completeChallengesInOrder,
            shuffle_challenges: x.shuffleChallenges,
            shuffle_challenges_preserve_sequence: x.shuffleChallengesPreserveSequence,
            chapter_background_url: x.chapterBackgroundUrl && x.chapterBackgroundUrl.length > 0 ? x.chapterBackgroundUrl : null,
            chapter_music_url: x.chapterMusicUrl && x.chapterMusicUrl.length > 0 ? x.chapterMusicUrl : null,
            has_virtual_spaces: x.hasVirtualSpaces,
            virtual_spaces: x.hasVirtualSpaces ? x.virtualSpaces : null,
            min_completions_per_task_category: x.minCompletionsPerTaskCategory,
            challenges: x.challenges.map(y => {
              return {
                name: y.name,
                briefing: y.briefing,
                type: y.type,
                answers: y.answers,
                hint: !y.hint ? null : {
                  reveal_after_x_tries: y.hint.revealAfterXTries,
                  reveal_after_x_mins: y.hint.revealAfterXMins,
                  credits: y.hint.credits,
                  hint: y.hint.hint,
                  penalty: y.hint.penalty
                },
                points: y.points,
                wrong_answer_penalty: y.wrongAnswerPenalty,
                time_penalty: y.timePenalty,
                lose_x_points_per_min: y.loseXPointsPerMin,
                lose_x_points_per_x_secs: y.loseXPointsPerXSecs,
                limit_to_x_tries: y.limitToXTries,
                skippable: y.skippable,
                skip_penalty: y.skipPenalty,
                skippable_after_x_tries: y.skippableAfterXTries,
                skippable_after_x_mins: y.skippableAfterXMins,
                item: y.item,
                item_category: y.itemCategory,
                item_order: y.itemOrder,
                show_specific_players_only: y.showSpecificPlayersOnly,
                custom_completion_message: y.customCompletionMessage,
                custom_failure_message: y.customFailureMessage,
                custom_judgement_message: y.customJudgementMessage,
                open_next_challenge: y.openNextChallenge,
                hide_next_challenge: y.hideNextChallenge,
                max_completions_per_session: y.maxCompletionsPerSession,
                access_code: y.accessCode,
                redo_after_x_mins: y.redoAfterXMins,
                hide_answer_if_not_answered_correctly: y.hideAnswerIfNotAnsweredCorrectly,
                no_stage_points_if_not_answered_correctly: y.noStagePointsIfNotAnsweredCorrectly,
                hide_after_completion: y.hideAfterCompletion,
                time_restricted: y.timeRestricted,
                time_restricted_allow_after: y.timeRestrictedAllowAfter,
                time_restricted_allow_after_date: y.timeRestrictedAllowAfterDate,
                time_restricted_allow_before: y.timeRestrictedAllowBefore,
                time_restricted_allow_before_date: y.timeRestrictedAllowBeforeDate,
                time_restricted_hide_outside_timeframe: y.timeRestrictedHideOutsideTimeframe,
                limit_teams_in_shuffle: y.limitTeamsInShuffle,
                show_only_after_x_minutes: y.showOnlyAfterXMinutes,
                show_only_after_x_chapter_points: y.showOnlyAfterXChapterPoints,
                show_only_after_x_chapter_points_task_category: y.showOnlyAfterXChapterPointsTaskCategory,
                do_not_add_points_to_leaderboard: y.doNotAddPointsToLeaderboard,
                mandatory: y.mandatory,
                must_complete_with_x_other_teams: y.mustCompleteWithXOtherTeams,
                task_list_icon_image: y.taskListIconImage,
                position: y.position,
                powerup_text: y.powerupText,
                powerup_stars: y.powerupStars,
                powerups: y.powerups,
                task_category: y.taskCategory
              }
            })
          }
        }),
        searchableItems: this.searchableItems.map(x => {
          let position = null
          if (x.lat && x.lon && x.radius_in_meters) {
            position = {
              lat: x.lat,
              lon: x.lon,
              radius_in_meters: x.radius_in_meters
            }
          }
          return {
            item: x.item,
            scanCode: x.scanCode,
            position,
            shown_on_map: x.shown_on_map,
            stage: x.stage,
            minutes: x.minutes,
            points: x.points,
            taskCategory: x.taskCategory,
            category: x.category,
            order: x.order
          }
        })
      }
      masterFunction({
        methodName: 'create-adventure',
        adventureId: this.adventureId,
        adventureJson: JSON.stringify(adventureData),
        rawAdventureJson: this.cleanedRawAdventureJson
      }).then(result => {
        this.loading = false
        if (result.data.errors) {
          this.error = true
          this.errorsObj = result.data.errors
          this.message = JSON.stringify(result.data.errors, null, 2)
          this.$buefy.toast.open({
            message: 'Errors found, please fix fields in red.',
            type: 'is-danger',
            queue: false
          })
        } else {
          this.unsavedChanges = -1
          if (!this.adventureId) {
            this.reset(false)
            this.$buefy.toast.open({
              message: 'Successfully created',
              type: 'is-primary',
              queue: false
            })
            this.$router.push({ name: 'managePlayTour', params: { adventureId: result.data.adventureId } })
          }
          this.error = false
          this.errorsObj = null
          this.message = null
          if (testPageStage) {
            const confirmed = confirm('All location checks are removed, so you can complete GPS tasks, and view tasks that are location-based wherever you are.')
            if (!confirmed) { return }
            this.openPage(
              `${this.$store.state.baseUrl}/play/join/${this.adventureId}/TESTING${testPageStage ? `-${testPageStage}` : ''}`
            )
          }
          setTimeout(() => {
            this.$store.commit('savedPlayTours', true)
          }, 1500)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    cleanRawAdventureJson (rawAdventureJson) {
      const dirty = JSON.parse(rawAdventureJson)
      const allowedKeys = [
        'name', 'previewImageUrl', 'shuffleChapters', 'shuffleChaptersNotFirstLast',
        'shuffleChaptersPreserveSequence', 'preventBunching', 'briefing', 'debrief',
        'allowTranslation', 'defaultLanguage', 'redirectUrl', 'timeLimitInMinutes',
        'endAfterTimeLimit', 'startingCredits', 'stages', 'searchableItems', 'uiMods'
      ]
      const cleaned = {}
      for (const key in dirty) {
        if (allowedKeys.includes(key)) {
          cleaned[key] = dirty[key]
        }
      }
      return JSON.stringify(cleaned)
    },
    getErrorMessage (key, index) {
      if (!this.error) {
        return null
      } else {
        const errors = this.errorsObj.filter(el => {
          return (
            el.key === key &&
            (
              (
                index === null && el.index === null
              ) ||
              (
                el.index && index &&
                el.index.index === index.index &&
                el.index.stageIndex === index.stageIndex &&
                el.index.challengeIndex === index.challengeIndex &&
                el.index.answerIndex === index.answerIndex &&
                el.index.sceneIndex === index.sceneIndex &&
                el.index.adjacentSceneIndex === index.adjacentSceneIndex
              )
            )
          )
        })
        if (errors.length > 0) {
          return errors.map(el => el.message).join('; ')
        } else {
          return null
        }
      }
    },
    calculateTotalTaskPoints (stageIndex) {
      return this.stages[stageIndex].challenges.reduce((total, challenge) => {
        return total + (challenge.points ? challenge.points : 0)
      }, 0)
    },
    restoreBackup (rawAdventureJson) {
      const confirmed = confirm('Your current unsaved changes will be replaced. Confirm?')
      if (!confirmed) {
        return
      }
      const jsonObj = JSON.parse(rawAdventureJson)
      jsonObj.message = null
      delete jsonObj.adventure
      delete jsonObj.error
      delete jsonObj.errorsObj
      for (const property in jsonObj) {
        this[property] = jsonObj[property]
      }
      this.stages.forEach(el => {
        el.show = false
      })
      this.showUiModsBox = false
      this.showEndingBox = false
      this.showItemsBox = false
      this.showMainDetailsBox = false
      this.showRestoreModal = false
      this.$buefy.toast.open({
        message: 'Successfully restored, click "Save Changes" to confirm',
        type: 'is-primary',
        queue: false
      })
    },
    copyTasksToAnotherChapter (currentStageIndex) {
      const targetChapter = prompt(`Enter the chapter number to copy tasks to (1 to ${this.stages.length}):`)
      const targetIndex = parseInt(targetChapter, 10) - 1

      if (
        isNaN(targetIndex) ||
        targetIndex < 0 ||
        targetIndex >= this.stages.length ||
        targetIndex === currentStageIndex
      ) {
        alert(`Invalid selection, please type a number from 1 to ${this.stages.length}, and not the current chapter.`)
        return
      }

      const tasksToCopy = JSON.parse(JSON.stringify(this.stages[currentStageIndex].challenges))
      this.stages[targetIndex].challenges.push(...tasksToCopy)

      this.$buefy.toast.open({
        message: `Tasks copied to Chapter ${targetChapter}.`,
        type: 'is-primary',
        queue: false
      })
    },
    updateTaskCategory ({ category, stageIndex, challengeIndex }) {
      this.$set(this.stages[stageIndex].challenges[challengeIndex], 'taskCategory', category)
      this.$buefy.toast.open({
        message: `Task category updated to ${category}.`,
        type: 'is-primary',
        queue: false
      })
    },
    initializeRawAdventureJson (rawAdventureJson, isPlayToursAI = false) {
      const toCamelCaseWithOriginal = (obj) => {
        if (Array.isArray(obj)) {
          return obj.map(v => toCamelCaseWithOriginal(v))
        } else if (obj !== null && obj.constructor === Object) {
          return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase())
            if (!(camelCaseKey in obj)) {
              acc[camelCaseKey] = toCamelCaseWithOriginal(obj[key])
            }
            acc[key] = toCamelCaseWithOriginal(obj[key])
            return acc
          }, {})
        }
        return obj
      }

      const jsonObj = toCamelCaseWithOriginal(JSON.parse(rawAdventureJson))
      jsonObj.message = null
      delete jsonObj.adventure
      delete jsonObj.error
      delete jsonObj.errorsObj
      for (const property in jsonObj) {
        this[property] = jsonObj[property]
      }
      this.stages.forEach(el => {
        el.show = false
      })
      this.showUiModsBox = false
      this.showEndingBox = false
      this.showItemsBox = false
      this.showMainDetailsBox = false
    },
    acknowledgeStageWithBelowMaximumPoints () {
      this.acknowledgedStageWithBelowMaximumPoints = !this.acknowledgedStageWithBelowMaximumPoints
      if (this.acknowledgedStageWithBelowMaximumPoints) {
        localStorage.setItem(`playToursAcknowledgedStageWithBelowMaximumPoints-${this.adventureId}`, 'true')
      } else {
        localStorage.removeItem(`playToursAcknowledgedStageWithBelowMaximumPoints-${this.adventureId}`)
      }
    }
  },
  updated () {
    const dataToSave = JSON.parse(JSON.stringify(this.$data))
    delete dataToSave.adventure
    delete dataToSave.unsavedChanges
    delete dataToSave.showRestoreModal
    delete dataToSave.showMainDetailsBox
    delete dataToSave.showItemsBox
    delete dataToSave.showEndingBox
    delete dataToSave.showUiModsBox
    delete dataToSave.shownChallenge
    dataToSave.stages.forEach(stage => {
      delete stage.show
    })
    if (!this.adventureId) {
      if (dataToSave.stages.length > 0 || dataToSave.name) {
        localStorage.monabrunJson = JSON.stringify(dataToSave)
      }
    } else {
      if (localStorage[`monabrunJsonEdit${this.adventureId}`] !== JSON.stringify(dataToSave)) {
        this.unsavedChanges = this.unsavedChanges + 1
        localStorage[`monabrunJsonEdit${this.adventureId}`] = JSON.stringify(dataToSave)
        console.log('hi')
        if (this.mountedAt && (Date.now() - this.mountedAt) > 5000) {
          this.$store.commit('savedPlayTours', false)
        }
      }
    }

    this.cleanedRawAdventureJson = this.adventureId
      ? this.cleanRawAdventureJson(localStorage[`monabrunJsonEdit${this.adventureId}`])
      : this.cleanRawAdventureJson(localStorage.monabrunJson)
  },
  mounted () {
    if (
      !this.$store.state.profile.isRootAccount &&
      this.$store.state.organisation.adventureVisibilities &&
      this.$store.state.organisation.adventureVisibilities[this.$route.params.adventureId] &&
      !this.$store.state.organisation.adventureVisibilities[this.$route.params.adventureId].includes(this.$store.state.profile.id)
    ) { this.$router.push({ name: 'home' }) }

    if (
      !this.adventureId &&
      (
        localStorage.monabrunJson ||
        this.$store.state.assistantJsonObj
      )
    ) {
      const jsonObj = this.$store.state.assistantJsonObj ? this.$store.state.assistantJsonObj : JSON.parse(localStorage.monabrunJson)

      if (this.$store.state.assistantJsonObj) {
        localStorage.monabrunJson = JSON.stringify(this.$store.state.assistantJsonObj)
      }

      jsonObj.message = null
      jsonObj.loading = false
      for (const property in jsonObj) {
        this[property] = jsonObj[property]
      }
    }

    this.$store.commit('savedPlayTours', true)
    if (this.adventureId) {
      this.$store.dispatch(
        'gameBuilderActivityAction',
        { adventureId: this.adventureId, isEnter: true }
      )
    }

    if (this.adventureId) {
      this.gameBuilderActivityInterval = setInterval(() => {
        this.$store.dispatch(
          'gameBuilderActivityAction',
          { adventureId: this.adventureId, isEnter: true }
        )
      }, 300000)
    }

    setTimeout(() => { this.unsavedChanges = 0 }, 2000)

    if (
      this.stageWithBelowMaximumPoints &&
      this.stageWithBelowMaximumPoints.length > 0 &&
      this.adventureId &&
      localStorage.getItem(`playToursAcknowledgedStageWithBelowMaximumPoints-${this.adventureId}`)
    ) {
      this.acknowledgedStageWithBelowMaximumPoints = true
    }
  },
  computed: {
    organisation () {
      return this.$store.state.organisation
    },
    shownStage () {
      if (this.stages && this.stages.filter(stage => stage.show).length > 0) {
        return this.stages.filter(stage => stage.show)[0]
      }
      return null
    },
    shownStageIndex () {
      if (this.stages && this.stages.filter(stage => stage.show).length > 0) {
        this.stages.forEach((stage, index) => {
          stage.index = index
        })
        return this.stages.filter(stage => stage.show)[0].index
      }
      return null
    },
    errorTasks () {
      if (!this.errorsObj) {
        return {}
      } else {
        const errorTasks = {}
        this.errorsObj.forEach(el => {
          if (el.index && el.index.stageIndex !== null && el.index.challengeIndex !== null) {
            if (!errorTasks[el.index.stageIndex]) {
              errorTasks[el.index.stageIndex] = []
            }
            errorTasks[el.index.stageIndex].push(el.index.challengeIndex)
          }
        })
        return errorTasks
      }
    },
    errorSections () {
      if (!this.errorsObj) {
        return []
      } else {
        const errorSections = this.errorsObj.map(el => {
          if (el.index && el.index.fieldName && el.index.fieldName === 'searchableItems') {
            return 'items'
          } else if (el.index && el.index.stageIndex !== null) {
            return el.index.stageIndex
          } else if (!el.index) {
            if (['debrief', 'redirect_url'].includes(el.key)) {
              return 'ending'
            }
            return 'details'
          }
        })
        return errorSections
      }
    },
    stageWithBelowMaximumPoints () {
      if (!this.stages || this.stages.length === 0) {
        return null
      } else {
        const stagesWithBelowMaxPoints = this.stages.filter((stage, index) => {
          stage.index = index
          const totalStagePoints = stage.challenges.reduce((total, challenge) => {
            return total + challenge.points
          }, 0)
          return stage.showMinPoints && stage.minPoints < totalStagePoints
        }).map(stage => stage.index + 1)
        return stagesWithBelowMaxPoints.length === 0 ? null : stagesWithBelowMaxPoints
      }
    },
    savedPlayTours () {
      return this.$store.state.savedPlayTours
    }
  },
  watch: {
    adventureId: {
      immediate: true,
      handler (adventureId) {
        const adventures = firebaseApp.firestore().collection('adventures')
        if (adventureId) {
          this.$bind('adventure', adventures.doc(adventureId))
        }
      }
    },
    adventure (newAdventure, oldAdventure) {
      if (!oldAdventure && newAdventure) {
        this.$emit('adventureName', newAdventure.details.adventure_details.name)

        this.initializeRawAdventureJson(newAdventure.rawAdventureJson)
      }
    },
    shuffleChaptersPreserveSequence (newValue) {
      if (newValue && this.unsavedChanges) {
        alert("This will randomize a team's starting location but the sequence of chapters will be preserved. For example, for a 5-chapter game, a team can start at chapter 3, then go to 4 -> 5 -> 1 -> 2.")
      }
    },
    preventBunching (newValue) {
      if (newValue && this.unsavedChanges) {
        alert('Dynamically set the next chapter to the least crowded chapter at the point of chapter completion.')
      }
    }
  },
  beforeDestroy () {
    if (this.adventureId) {
      this.$store.dispatch(
        'gameBuilderActivityAction',
        { adventureId: this.adventureId, isEnter: false }
      )
    }

    if (this.gameBuilderActivityInterval) clearInterval(this.gameBuilderActivityInterval)
  }
}
</script>

<style>
.stage-header {
  align-items: center;
  background-color: #f2effb;
  border-radius: 4px 4px;
  color: #4a4a4a;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
  position: relative;
}

.stage-connector {
  border-left: 3px solid #b9b9b9;
  height: 16px;
  margin-left: 30px;
  /* position:absolute; */
  left: 50%;
}

.main-box {
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #e8e8e8;
  border-radius: 10px;
}

.task-box {
  background-color: white;
  border-radius: 20px;
  padding: 9px 15px !important;
}

.magnifying-glass {
  cursor: pointer;
  height: 23px;
  width: 23px;
  opacity: 0.9;
  content: url(https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2Fmagnifying-glass.png?alt=media&token=1c735196-9efd-41e7-92c9-a08d3ff50c93);
}

.pnlm-panorama-info {
  visibility: visible !important;
}

.task-body-modifier {
  height: 100%;
}

.warning-background-animation {
  background: linear-gradient(-45deg, white, hsl(55, 80%, 71%), white);
  background-size: 400% 400%;
  animation: gradient-animation 4.5s linear infinite;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.answer-options-container-modifier {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: stretch;
}

.answer-options-mcq-container-modifier {
  display: flex;
  justify-content: start;
  gap: 15px;
  align-items: stretch;
}

.answer-options-container-modifier > * {
  flex: 1;
}
</style>
