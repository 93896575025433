<template>
  <div class="is-flex is-justify-content-flex-end mb-1">
    <div @click="downloadFile()" class="button is-primary" :class="{ 'is-loading': loading }">
      <i class="fas fa-stream mr-2"></i>
      Download leaderboard
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import { utils, writeFile } from 'xlsx'

export default {
  name: 'DownloadLeaderboard',
  props: {
    ranking: {
      type: Array,
      default: null
    },
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      leaderboard: null,
      loading: false
    }
  },
  computed: {
    filteredRanking () {
      if (this.ranking) {
        return this.ranking
      }
      if (this.leaderboard) {
        if (this.session) {
          return this.leaderboard.ranking.filter(el => {
            return el.sessionId && el.sessionId === this.session.id
          })
        }
        return this.leaderboard.ranking.filter(el => {
          return !el.sessionId
        })
      } else {
        return []
      }
    }
  },
  methods: {
    async downloadFile () {
      this.loading = true
      const filename = `playtours_leaderboard_${new Date().toISOString()}.xlsx`
      const headers = [['Team Name', 'Points', 'Completion time (minutes)']]
      const data = this.filteredRanking.map(rank => {
        return [
          rank.teamName,
          rank.points,
          rank.timeTakenToComplete ? (rank.timeTakenToComplete / 60).toFixed(3) : ''
        ]
      })
      const aoa = headers.concat(data) // Combine headers and data

      // Create a new workbook and add the data
      const ws = utils.aoa_to_sheet(aoa)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Leaderboard')

      // Generate and download Excel file
      writeFile(wb, filename)
      this.loading = false
    }
  },
  mounted () {
    if (!this.ranking && this.session) {
      const leaderboards = firebaseApp.firestore().collection('leaderboards')
      if (this.session) {
        this.$bind('leaderboard', leaderboards.doc(this.session.adventureId))
      }
    }
  }
}
</script>
